import axiosInstance from "./api_helper";

const producteurService = {
  search: async (query) => {
    return axiosInstance.post(`/producteurs/search`, JSON.stringify(query));
  },
  delete: async (id) => {
    return axiosInstance.delete(`/producteurs/${id}`);
  },
  dashboard: async (id) => {
    return axiosInstance.get(`/producteurs/dashboard?campagneId=${id}`);
  },
  findAll: async () => {
    const response = await axiosInstance.get(`/producteurs`);
    return response;
  },
};
export default producteurService;
