import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Container, Spinner, Input, FormGroup, Label, Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { userActions } from '../../sagas/userSlice';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import { FaAccessibleIcon, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaBox, FaCalendarTimes, FaChevronLeft, FaChevronRight, FaCog, FaEdit, FaFlag, FaMap, FaPlus, FaSearch, FaTag, FaTrash, FaUser } from 'react-icons/fa';
import { debounce } from 'lodash';

const UserList = () => {
  const dispatch = useDispatch();
  const {
    isFetching,
    totalPages,
    users,
    totalElements,
    pageNumber,
    pageSize,
    error
  } = useSelector((state) => state.user);

  const masterData = useSelector((state) => state.masterData.data);
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [criteria, setSearchCriteria] = useState({ query: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Track selected row IDs
  const [isSearching, setIsSearching] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    let payload = {
      criteria,
      currentPage,
      size: pageSize,
    };
    dispatch(userActions.search(payload));
  }, [currentPage]);


  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      setIsSearching(true);
      dispatch(userActions.search({
        criteria: { query },
        page: 1,
        size: pageSize,
        onSuccess: () => {
          setIsSearching(false)
          setTimeout(() => {
            searchInputRef.current?.focus();
          }, 0);
        }
      }));
    }, 1000), // Wait 500ms after last keystroke
    []
  );


  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };


  const getDistrictName = (id) => {
    return masterData?.districts?.find((district) => district.id === id)?.description || 'N/A';
  };

  const getRegionName = (id) => {
    return masterData?.regions?.find((region) => region.id === id)?.description || 'N/A';
  };

  const getZoneName = (id) => {
    return masterData?.zones?.find((zone) => zone.id === id)?.description || 'N/A';
  };

  if (isFetching) {
    return <Spinner color="success" />;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      text: "Cette action supprimera définitivement cette utilisateur. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id,
          onSuccess: () => {
            dispatch(
              userActions.search({
                criteria,
                page: currentPage,
                size: pageSize,
              })
            );
            Swal.fire({
              title: "Supprimé !",
              text: "L'utilisateur' a été supprimée avec succès.",
              icon: "success",
              confirmButtonText: "OK",
            });
          },
          onError: (error) => {
            Swal.fire({
              title: "Erreur !",
              text:
                error.message ||
                "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log("Error : ", error);
          },
        };

        dispatch(userActions.delete(payload));
        console.log(payload);
      }
    });
  };

  const goEditPage = (id) => {
    navigate(`/users/form/${id}?token=${localStorage.getItem("token")}`);
  };

  // Helper function to set different background colors for each status
  const getStatusBackgroundColor = (status) => {
    if (status === "Actif") return "green"; // Green for Approved
    if (status === "Inactif") return "red"; // Red for Canceled
  };

  const statusStyles = (status) => {
    return {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "8px",
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: "center",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light shadow to give a 3D effect
      margin: "4px 0",
      backgroundColor: getStatusBackgroundColor(status),
      color: "white",
    };
  };

  const handleExportExcel = () => {
    let payload = {
      criteria,
      page: pageNumber,
      size: pageSize,
      onSuccess: (data) => {
        console.log(data);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = "transactions.xlsx";
        link.click();

        Swal.fire({
          icon: "success",
          title: "Exportation réussie",
          text: "Le fichier Excel des transactions a été généré et téléchargé avec succès.",
          confirmButtonText: "Super !",
        });
      },
      onError: (error) => {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "Échec de l'exportation",
          text: `Une erreur s'est produite lors de la génération du fichier des transactions. Veuillez réessayer plus tard.
                 Erreur : ${error.message || "Erreur inconnue"}`,

          confirmButtonText: "Ok",
        });
      },
    };
    //dispatch(transactionActions.generateExcelTransaction(payload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <Card className="shadow-sm border-0">
            <CardBody className="py-3">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left side - Title */}
                <h4 className="mb-0 d-flex align-items-center">
                  <FaBox className="text-success me-2" />
                  {t('users.main')}
                </h4>

                {/* Right side - Breadcrumb */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/users" className="text-decoration-none">
                        {t('users.main')}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="currentPage">
                      {t('users.main')}
                    </li>
                  </ol>
                </nav>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pe-4">
          <Card>
            <CardBody>
              {isFetching ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">{t('common.loading')}</span>
                  </div>
                  <div className="mt-2 text-muted">
                    {t('common.loadingData')}
                  </div>
                </div>
              ) : (<>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* Search Input - Left Side */}
                  <div className="position-relative flex-grow-1 me-3">
                    <Input
                      innerRef={searchInputRef}
                      type="text"
                      placeholder={t('producteur.searchPlaceholder')}
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className={`ps-4 ${isSearching ? 'searching' : ''}`}
                    />
                    <FaSearch
                      style={{
                        position: 'absolute',
                        left: '10px',
                        top: '12px',
                        color: '#6c757d'
                      }}
                    />
                  </div>
                  {/* Buttons - Right Side */}
                  <div className="d-flex mx-3">
                    <Button color="success" size="sm"
                      className="me-2" onClick={() => navigate("/users/form")}>
                      <FaPlus className="me-2" />
                      {t('user.new')}
                    </Button>
                  </div>
                </div>
                <div className="cart-container" style={{
                  height: '400px',
                  overflowY: 'auto'
                }}>
                  <Table hover bordered className="align-middle mb-0">
                    <thead className="bg-light"><tr>
                      <th className="align-middle">
                        <FaTag className="me-2 text-success" size={12} />
                        {t("user.code")}
                      </th>
                      <th className="align-middle">
                        <FaCalendarTimes className="me-2 text-success" size={12} />
                        {t('user.dateCreation')}
                      </th>
                      <th className="align-middle text-end">
                        <FaUser className="me-2 text-success" size={12} />
                        {t('user.fullname')}
                      </th>
                      <th className="align-middle text-end">
                        <FaTag className="me-2 text-success" size={12} />
                        {t('user.district')}
                      </th>
                      <th className="align-middle text-end">
                        <FaTag className="me-2 text-success" size={12} />
                        {t('user.region')}
                      </th>
                      <th className="align-middle text-end">
                        <FaMap className="me-2 text-success" size={12} />
                        {t('user.zone')}
                      </th>

                      <th className="align-middle text-end">
                        <FaAccessibleIcon className="me-2 text-success" size={12} />
                        {t('user.roles')}
                      </th>
                      <th className="align-middle text-end">
                        <FaFlag className="me-2 text-success" size={12} />
                        {t('Etat')}
                      </th>
                      <th className="align-middle text-center" style={{ width: '120px' }}>
                        <FaCog className="me-2 text-success" size={12} />
                        {t('common.actions')}
                      </th>
                    </tr>
                    </thead><tbody>
                      {!users || users.length === 0 ? (
                        <tr>
                          <td colSpan="6" className="text-center py-4">
                            <div className="text-muted">
                              {t('user.noUsers')}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        users.map((user) => (
                          <tr key={user.id}>
                            <td className="align-middle">{user.code}</td>
                            <td className="align-middle">{user.createdDate}</td>
                            <td className="align-middle">{`${user.nom} ${user.prenom}`}</td>
                            <td className="align-middle text-center">{getDistrictName(user.districtId)}</td>
                            <td className="align-middle text-end">{getRegionName(user.regionId)}</td>
                            <td className="align-middle text-end">{getZoneName(user.zoneId)}</td>
                            <td className="align-middle">{user.roles.join(', ')}</td>
                            <td className="align-middle">
                              <div style={statusStyles(user.enabled ? 'Actif' : 'Inactif')}>{user.enabled ? 'Actif' : 'Inactif'}</div>
                            </td>

                            <td className="align-middle text-center">
                              <Button color="warning" size="sm" className="me-1 px-2 py-1">
                                <FaEdit size={12} onClick={() => goEditPage(user.id)} />
                              </Button>
                              <Button color="danger" size="sm" className="px-2 py-1">
                                <FaTrash size={12} onClick={() => handleDelete(user.id)} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <small className="text-muted">
                    {t('common.showing', {
                      start: (currentPage - 1) * pageSize + 1,
                      end: Math.min(currentPage * pageSize, totalElements),
                      total: totalElements,
                      currentPage: currentPage,
                      totalPages: totalPages
                    })}
                  </small>
                  <Pagination size="sm" className="mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink first onClick={() => handlePageChange(1)}>
                        <FaAngleDoubleLeft size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                        <FaAngleLeft size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                        <FaAngleRight size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink last onClick={() => handlePageChange(totalPages)}>
                        <FaAngleDoubleRight size={12} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </>)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserList;
