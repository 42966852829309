import axiosInstance from "./api_helper";

const BASE_URL = "/sections";

const sectionService = {
  search: async (payload) => {
    const { page = 0, size = 10 } = payload || {};
    const response = await axiosInstance.get(`${BASE_URL}/search`, {
      params: { page, size }
    });
    return response.data;
  },
  getById: async (id) => {
    const response = await axiosInstance.get(`${BASE_URL}/${id}`);
    return response.data;
  },
  create: async (dto) => {
    const response = await axiosInstance.post(BASE_URL, dto);
    return response.data;
  },
  update: async (id, dto) => {
    const response = await axiosInstance.put(`${BASE_URL}/${id}`, dto);
    return response.data;
  },
  remove: async (id) => {
    await axiosInstance.delete(`${BASE_URL}/${id}`);
  }
};

export default sectionService;