import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import producteurService from "../services/producteursService";

const producteursSlice = createModule({
  name: "producteurs",
  initialState: {
    producteurs: [],
    isFetching: false,
    error: null,
    totalElements: 0, // Total number of records
    totalPages: 0, // Total number of pages
    pageNumber: 0, // Current page number
    pageSize: 10, // Size of each page
  },
  reducers: {
    search: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    delete: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    dashboard: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchedData: (state, payload) => {
      state.producteurs = payload.body.content;
      state.totalElements = payload.body.totalElements; // Assuming totalElements is in payload
      state.totalPages = payload.body.totalPages; // Assuming totalPages is in payload
      state.pageNumber = payload.body.pageNumber;
    },
    findAll: (state) => {
      state.isFetching = true;
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },

    fetchError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload || "An error occurred";
    },
  },

  sagas: (A) => ({
    *[A.search]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield call(producteurService.search, payload);
        yield put(A.finishFetching());
        yield put(A.fetchedData(data));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.delete]({ payload }) {
      try {
        console.log(payload);
        yield call(producteurService.delete, payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e?.response?.data?.message ||
          e.message ||
          "Une erreur s'est produite lors de la suppression. Veuillez réessayer.";
        yield call(payload.onError, { message: errorMessage });
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.findAll]({ payload }) {
      try {
        const { data } = yield call(
          producteurService.findAll
        );
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.dashboard]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield call(
          producteurService.dashboard,
          payload.campagneId
        );
        console.log(data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
  }),
});

export default producteursSlice;
export const producteursActions = producteursSlice.actions;
