import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FaMapMarked, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaBarcode, FaBox, FaBoxes, FaBoxOpen, FaCalendarTimes, FaCog, FaEdit, FaFileDownload, FaFileUpload, FaImage, FaMap, FaPlus, FaQrcode, FaSearch, FaTag, FaTrash, FaMapMarkerAlt, FaTable, FaThLarge, FaCalendarAlt, FaEye, FaRedo, FaFileExcel } from 'react-icons/fa';
import { Button, Card, CardBody, CardHeader, CardImg, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, Toast, ToastBody, ToastHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { producteursActions } from '../../sagas/producteursSlice';
import { format, parse } from "date-fns";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import config from '../../helpers/config';
import { GoogleMap, LoadScript, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../helpers/constants';
import GpxParser from 'gpxparser';
import { parcelleActions } from '../../sagas/parcelleSlice';

const DEFAULT_COORDINATES = { latitude: 5.30966, longitude: -4.01266 }; // Abidjan

const ProducteurList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [criteria, setSearchCriteria] = useState({ query: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [parcelleModal, setParcelleModal] = useState(false);
  const [selectedParcelles, setSelectedParcelles] = useState([]);
  const [newParcelleModal, setNewParcelleModal] = useState(false); // Add this line
  const [parcelleImages, setParcelleImages] = useState([]);
  const [viewMode, setViewMode] = useState('card'); // 'table' or 'card'

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const getCampaignDescriptionById = (id) => {
    return masterData?.campagnes?.find(campagne => campagne.id === id)?.description || "N/A";
  };

  const getCultureById = (id) => {
    return masterData?.culturesAgricoles?.find(culture => culture.id === id)?.nomCulture || "N/A";
  };

  const [newParcelle, setNewParcelle] = useState({
    campagneId: '',
    codeParcelle: '',
    dateCreation: today, // Set default to today
    cultureAgricoleId: '',
    culturesEnvironnantes: '',
    varieteSemenceId: '',
    dateSemis: today, // Set default to today
    surfaceGps: 0,
    surfaceReelle: 0,
    productionReelle: 0,
    rendementReel: 0,
    gpxCoordinates: null

  });

  const mapContainerStyle = {
    height: '400px',
    width: '100%'
  };

  const [parcelleCoordinates, setParcelleCoordinates] = useState({
    latitude: DEFAULT_COORDINATES.latitude,
    longitude: DEFAULT_COORDINATES.longitude
  });
  const [mapCenter, setMapCenter] = useState(DEFAULT_COORDINATES);

  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const {
    producteurs,
    isFetching,
    totalPages,
    totalElements,
    pageNumber,
    pageSize,
    error,
  } = useSelector((state) => state.producteurs);
  const masterData = useSelector((state) => state.masterData.data);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    latitude: null,
    longitude: null,
  });
  const [selectedProducteur, setSelectedProducteur] = useState({});
  const navigate = useNavigate();
  const [dateFilters, setDateFilters] = useState({
    startDate: format(yesterday, 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd')
  });

  useEffect(() => {
    // Auto focus on mount
    searchInputRef.current?.focus();

    // Add keyboard shortcut
    const handleKeyDown = (e) => {
      if (e.key === 'F2') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleParcelles = (producteur) => {
    setSelectedProducteur(producteur);
    dispatch(parcelleActions.fetchByProducteurCode({
      code: producteur.codeProducteur,
      onSuccess: (data) => {
        setSelectedParcelles([...data]);
      }
    }));
    setParcelleModal(true);
  };

  const handleParcelleSubmit = (e) => {
    e.preventDefault();
    try {
      // Format dates
      const formattedParcelle = {
        ...newParcelle,
        producteurId: selectedProducteur.id,
        dateCreation: null,
        dateSemis: format(new Date(newParcelle.dateSemis), "dd/MM/yyyy"),
        // If using gpxCoordinates, format them properly
        coordinates: newParcelle.gpxCoordinates ? newParcelle.gpxCoordinates.map(coord => ({
          latitude: coord.lat,
          longitude: coord.lng
        })) : null
      };

      const parcelleJson = JSON.stringify(formattedParcelle);
      // Create FormData to send data as multipart/form-data
      const formData = new FormData();
      formData.append('parcelle', parcelleJson);
      formData.append('codeProducteur', selectedProducteur.codeProducteur);
      // Append images to FormData
      parcelleImages.forEach((image, index) => {
        // Convert base64 to Blob
        const byteString = atob(image.data.split(',')[1]);
        const mimeString = image.type;
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        formData.append('images', blob, image.name);
      });
      // Dispatch create action
      dispatch(parcelleActions.create({
        formData: formData,
        onSuccess: () => {
          handleParcelleModalClose();
          // Show success message
          Swal.fire({
            icon: 'success',
            title: t('parcelle.createSuccess'),
            text: t('parcelle.parcelleCreated'),
            confirmButtonColor: '#198754'
          });

          // Refresh parcelles list
          // Assuming you have an action to fetch parcelles by producteur
          dispatch(parcelleActions.fetchByProducteurCode({
            code: selectedProducteur.codeProducteur,
            onSuccess: (data) => {
              setSelectedParcelles([...data]);
            }
          }));
        },
        onError: (error) => {
          console.error('Error creating parcelle:', error);
          Swal.fire({
            icon: 'error',
            title: t('parcelle.createError'),
            text: error.message || t('parcelle.createErrorGeneric'),
            confirmButtonColor: '#dc3545'
          });
        }
      }));

    } catch (error) {
      console.error('Form submission error:', error);
      Swal.fire({
        icon: 'error',
        title: t('parcelle.createError'),
        text: t('parcelle.createErrorGeneric'),
        confirmButtonColor: '#dc3545'
      });
    }
  };

  useEffect(() => {
    let payload = {
      criteria: {
        ...criteria,
        startDate: dateFilters.startDate,
        endDate: dateFilters.endDate
      },
      page: currentPage,
      size: pageSize,
    };
    dispatch(producteursActions.search(payload));
  }, [currentPage]);

  // Add to your handleSearch function
  const handleSearch = (e) => {
    e.preventDefault();
    const searchPayload = {
      ...criteria,
      startDate: dateFilters.startDate,
      endDate: dateFilters.endDate
    };

    dispatch(producteursActions.search({
      criteria: searchPayload,
      page: 1,
      size: pageSize
    }));
  };

  // Add to your handleReset function
  const handleReset = () => {
    setDateFilters({
      startDate: format(yesterday, 'yyyy-MM-dd'),
      endDate: format(today, 'yyyy-MM-dd')
    });

    handleSearch();

  };


  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      setIsSearching(true);
      dispatch(producteursActions.search({
        criteria: { query },
        page: 1,
        size: pageSize,
        onSuccess: () => {
          setIsSearching(false)
          setTimeout(() => {
            searchInputRef.current?.focus();
          }, 0);
        }
      }));
    }, 1000), // Wait 500ms after last keystroke
    []
  );

  const handleCulturesSelection = (selected) => {
    setSelectedProducteur({
      ...selectedProducteur,
      cultures:
        selected.map((culture) => ({
          cultureAgricoleId: culture.value,
          nomCulture: culture.label,
          nombreParcelles: 0,
          surfaceEnHectare: 0.0,
          productionEstimeeKg: 0.0,
          chiffreAffaireEstime: 0.0,
        }))
    });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const refrechProducts = () => {
    let payload = {
      criteria,
      currentPage,
      size: pageSize,
    };
    dispatch(producteursActions.search(payload));
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      text: "Cette action supprimera définitivement cette producteur. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id,
          onSuccess: () => {
            dispatch(
              producteursActions.search({
                criteria,
                currentPage: currentPage,
                size: pageSize,
              })
            );
            Swal.fire({
              title: "Supprimé !",
              text: "Producteur a été supprimée avec succès.",
              icon: "success",
              confirmButtonText: "OK",
            });
          },
          onError: (error) => {
            Swal.fire({
              title: "Erreur !",
              text:
                error.message ||
                "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log("Error : ", error);
          },
        };

        dispatch(producteursActions.delete(payload));
        console.log(payload);
      }
    });
  };

  const districtDescription = (producteur) =>
    masterData?.districts?.find(
      (district) => district.id === producteur.districtId
    )?.description || "N/A";

  const regionDescription = (producteur) =>
    masterData?.regions?.find((region) => region.id === producteur.regionId)
      ?.description || "N/A";

  const prefectureDescription = (producteur) =>
    masterData?.perfectures?.find(
      (prefecture) => prefecture.id === producteur.prefectureId
    )?.nomPrefecture || "N/A";

  const sousPrefectureDescription = (producteur) =>
    masterData?.sousPerfectures?.find(
      (sousPrefecture) => sousPrefecture.id === producteur.sousPrefectureId
    )?.nomSousPrefecture || "N/A";

  const zoneDescription = (producteur) =>
    masterData?.zones?.find((zone) => zone.id === producteur.zoneId)
      ?.description || "N/A";

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (producteur) => {
    console.log(producteur)
    const formattedDateNaissance = producteur.dateNaissance
      ? format(parse(producteur.dateNaissance, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : '';

    const formattedDateEtablissementPiece = producteur.dateEtablissementPiece
      ? format(parse(producteur.dateEtablissementPiece, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : '';

    setSelectedProducteur({
      ...producteur,
      dateNaissance: formattedDateNaissance,
      dateEtablissementPiece: formattedDateEtablissementPiece,
    });

    setEditModal(true);
  };

  const handleUpdate = () => {
    let payload = {
      id: selectedProducteur.id,
      producteur: selectedProducteur,
      onSuccess: () => {
        setEditModal(false);
        refrechProducts();
        Swal.fire(t('product.updated'), '', 'success');
      }
    };
    dispatch(producteursActions.update(payload));
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ['places'],
  });


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        ...selectedProducteur,
        dateNaissance: format(new Date(selectedProducteur.datenaiss), "dd/MM/yyyy"),
        dateEtablissementPiece: selectedProducteur.dateEtablissementPiece ? format(new Date(selectedProducteur.dateEtablissementPiece), "dd/MM/yyyy") : null,
      };

      // Validate required fields
      if (!formData.description || !formData.userPrice) {
        return;
      }

      // Create product
      dispatch(producteursActions.create({
        producteur: formData,
        onSuccess: () => {
          toggleModal();
          // Reset form
          e.target.reset();
          Swal.fire({
            icon: 'success',
            title: t('product.addSuccess'),
            showConfirmButton: false,
            timer: 1500
          });

          // Refresh list
          let payload = {
            criteria,
            currentPage,
            size: pageSize,
          };
          dispatch(producteursActions.search(payload));
        },
        onError: (error) => {
          console.error('Create product error:', error);
        }
      }));

    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  const downloadTemplate = () => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const headers = [
      ['Description', 'Code-barres', 'Stock', 'Prix de vente', 'Prix d\'achat']
    ];

    // Create worksheet with headers
    const ws = XLSX.utils.aoa_to_sheet(headers);

    // Set column widths
    const colWidths = [
      { wch: 10 },  // Code
      { wch: 30 },  // Description
      { wch: 15 },  // Barcode
      { wch: 10 },  // Stock
      { wch: 10 }   // Price
    ];
    ws['!cols'] = colWidths;

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Products Template");

    // Generate and download file
    XLSX.writeFile(wb, "products_template.xlsx");
  };

  // Add to your handleFilterChange function
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate' || name === 'endDate') {
      setDateFilters(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };


  const coordinates = {
    latitude: selectedProducteur?.latitude || DEFAULT_COORDINATES.latitude,
    longitude: selectedProducteur?.longitude || DEFAULT_COORDINATES.longitude,
  };

  // Add this function after your imports
  const calculatePolygonArea = (coordinates) => {
    if (!coordinates || coordinates.length < 3) return 0;

    let area = 0;
    const R = 6371000; // Earth's radius in meters

    for (let i = 0; i < coordinates.length; i++) {
      const j = (i + 1) % coordinates.length;
      const xi = coordinates[i].lng * Math.PI / 180;
      const yi = coordinates[i].lat * Math.PI / 180;
      const xj = coordinates[j].lng * Math.PI / 180;
      const yj = coordinates[j].lat * Math.PI / 180;

      area += (xj - xi) * (2 + Math.sin(yi) + Math.sin(yj));
    }

    area = Math.abs(area * R * R / 2);
    return area;
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      Swal.fire({
        icon: 'error',
        title: t('parcelle.tooManyImages'),
        text: t('parcelle.max3Images'),
        confirmButtonColor: '#dc3545'
      });
      return;
    }

    Promise.all(
      files.map(file => {
        return new Promise((resolve, reject) => {
          if (file.size > 2048000) { // 2MB
            reject({
              file: file.name,
              error: t('parcelle.imageTooLarge')
            });
          }

          const reader = new FileReader();
          reader.onload = (event) => {
            resolve({
              name: file.name,
              data: event.target.result,
              type: file.type
            });
          };
          reader.onerror = () => {
            reject({
              file: file.name,
              error: t('parcelle.imageReadError')
            });
          };
          reader.readAsDataURL(file);
        });
      })
    )
      .then(imageDataArray => {
        setParcelleImages([...parcelleImages, ...imageDataArray]);
      })
      .catch(error => {
        console.error('Image upload error:', error);
        Swal.fire({
          icon: 'error',
          title: t('parcelle.imageUploadError'),
          text: `${error.file}: ${error.error}`,
          confirmButtonColor: '#dc3545'
        });
      });
  };

  const removeImage = (index) => {
    const newImages = [...parcelleImages];
    newImages.splice(index, 1);
    setParcelleImages(newImages);
  };

  const handleEditParcelle = (parcelle) => {
    // Format dates for the date pickers
    const formattedDateCreation = format(new Date(parcelle.dateCreation), "yyyy-MM-dd");
    const formattedDateSemis = parcelle.dateSemis ? format(new Date(parcelle.dateSemis), "yyyy-MM-dd") : '';

    // Set the parcelle data to the form
    setNewParcelle({
      campagneId: parcelle.campagneId,
      codeParcelle: parcelle.codeParcelle,
      dateCreation: formattedDateCreation,
      cultureAgricoleId: parcelle.cultureAgricoleId,
      culturesEnvironnantes: parcelle.culturesEnvironnantes,
      varieteSemenceId: parcelle.varieteSemenceId,
      dateSemis: formattedDateSemis,
      surfaceGps: parcelle.surfaceGps,
      surfaceReelle: parcelle.surfaceReelle,
      productionReelle: parcelle.productionReelle,
      rendementReel: parcelle.rendementReel,
      gpxCoordinates: parcelle.coordinates ? parcelle.coordinates.map(coord => ({
        lat: coord.latitude,
        lng: coord.longitude
      })) : null,
      latitude: parcelle.latitude,
      longitude: parcelle.longitude
    });

    setParcelleCoordinates({
      latitude: parcelle.coordinates[0].latitude,
      longitude: parcelle.coordinates[0].longitude
    });

    // Set the images
    if (parcelle.imagesParcelle && parcelle.imagesParcelle.length > 0) {
      setParcelleImages([...parcelle.imagesParcelle]);
    } else {
      setParcelleImages([]);
    }

    // Open the modal
    setNewParcelleModal(true);
  };

  const handleExport = async () => {

    Swal.fire({
      title: t('producteur.export.loading'),
      text: t('producteur.export.wait'),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let playload = {
      criteria: {
        ...criteria,
        startDate: dateFilters.startDate,
        endDate: dateFilters.endDate
      },
      page: 1,
      size: 100000, // Fetch all records
    };

    dispatch(producteursActions.findAll({
      ...playload,
      onSuccess: (response) => {
        const data = response;

        // Format data for Excel
        const excelData = data.map(producteur => ({
          'Code Producteur': producteur.codeProducteur,
          'Nom et Prénoms': producteur.nomPrenoms,
          'Date de Naissance': producteur.dateNaissance ? new Date(producteur.dateNaissance).toLocaleDateString() : '',
          'Lieu de Naissance': producteur.lieuNaissance,
          'Nom du Père': producteur.nomPere,
          'Nom de la Mère': producteur.nomMere,
          'Genre': producteur.genre,
          'Numéro de Téléphone': producteur.numeroTelephone,
          'Numéro CMU': producteur.numeroCmu,
          'Type de Pièce d\'Identification': producteur.typePieceIdentification,
          'Numéro de Pièce': producteur.numeroPiece,
          'Date d\'Établissement de la Pièce': producteur.dateEtablissementPiece ? new Date(producteur.dateEtablissementPiece).toLocaleDateString() : '',
          'Ethnie': masterData?.ethnies?.find(ethnie => ethnie.id === producteur.ethnie)?.description || '',
          'Origine': producteur.origine,
          'Situation Matrimoniale': producteur.situationMatrimoniale,
          'Nationalité': producteur.nationalite,
          'Nombre de Femmes': producteur.nombreFemmes,
          'Nombre d\'Enfants': producteur.nombreEnfants,
          'Niveau d\'Étude': producteur.niveauEtude,
          'Campagne': masterData?.campagnes?.find(campagne => campagne.id === producteur.campagneId)?.description || '',
          'District': masterData?.districts?.find(district => district.id === producteur.districtId)?.description || '',
          'Région': masterData?.regions?.find(region => region.id === producteur.regionId)?.description || '',
          'Zone': masterData?.zones?.find(zone => zone.id === producteur.zoneId)?.description || '',
          'Préfecture': masterData?.perfectures?.find(prefecture => prefecture.id === producteur.prefectureId)?.nomPrefecture || '',
          'Sous-Préfecture': masterData?.sousPerfectures?.find(sousPrefecture => sousPrefecture.id === producteur.sousPrefectureId)?.nomSousPrefecture || '',
          'Localité': producteur.localite,
          'Coopérative': masterData?.cooperatives?.find(cooperative => cooperative.id === producteur.cooperativeId)?.description || '',
          'Faitière': masterData?.faitieres?.find(faitiere => faitiere.id === producteur.faitiereId)?.description || '',
          'Cultures Agricoles': producteur.cultures?.map(culture => culture.nomCulture).join(', ') || '',
          'Latitude': producteur.latitude,
          'Longitude': producteur.longitude,
        }));

        // Create worksheet
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Producteurs');

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create download link
        const fileName = `producteurs${new Date().toISOString().split('T')[0]}.xlsx`;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();

        Swal.fire({
          title: t('producteur.export.successTitle'),
          text: t('producteur.export.successText'),
          icon: 'success',
          timer: 1500
        });

      }, onError: (error) => {
        Swal.fire({
          title: t('producteur.export.errorTitle'),
          text: t('producteur.export.errorText'),
          icon: 'error'
        });
      }
    }));

  };

  const handleDeleteParcelle = (id) => {
    Swal.fire({
      title: t('parcelle.deleteConfirmation'),
      text: t('parcelle.deleteWarning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: t('common.yesDelete'),
      cancelButtonText: t('common.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(parcelleActions.delete({
          id: id,
          onSuccess: () => {
            Swal.fire(
              t('parcelle.deleted'),
              t('parcelle.parcelleDeleted'),
              'success'
            );
            // Refresh parcelles list
            dispatch(parcelleActions.fetchByProducteurCode({
              code: selectedProducteur.codeProducteur,
              onSuccess: (data) => {
                setSelectedParcelles([...data]);
              }
            }));
          },
          onError: (error) => {
            Swal.fire(
              t('common.error'),
              error.message || t('parcelle.deleteErrorGeneric'),
              'error'
            );
          }
        }));
      }
    });
  };

  const handleParcelleModalClose = () => {
    // Reset all parcelle-related states
    setNewParcelle({
      campagneId: '',
      codeParcelle: '',
      dateCreation: today,
      cultureAgricoleId: '',
      culturesEnvironnantes: '',
      varieteSemenceId: '',
      dateSemis: today,
      surfaceGps: 0,
      surfaceReelle: 0,
      productionReelle: 0,
      rendementReel: 0,
      gpxCoordinates: null,
      latitude: null,
      longitude: null
    });

    setParcelleImages([]);
    setParcelleCoordinates({
      latitude: DEFAULT_COORDINATES.latitude,
      longitude: DEFAULT_COORDINATES.longitude
    });

    // Close the modal
    setNewParcelleModal(false);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Card className="shadow-sm border-0">
            <CardBody className="py-3">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left side - Title */}
                <h4 className="mb-0 d-flex align-items-center">
                  <FaBox className="text-success me-2" />
                  {t('producteur.pageTitle')}
                </h4>

                {/* Right side - Breadcrumb */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard" className="text-decoration-none">
                        {t('nav.dashboard')}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t('nav.producteurs')}
                    </li>
                  </ol>
                </nav>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pe-4">
          <Card className="shadow-sm h-100">
            <CardBody>
              {isFetching ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">{t('common.loading')}</span>
                  </div>
                  <div className="mt-2 text-muted">
                    {t('common.loadingData')}
                  </div>
                </div>
              ) : (<>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* Search Input - Left Side */}
                  <div className="d-flex align-items-center flex-grow-1 me-3 gap-2">
                    <div className="position-relative" style={{ width: '300px' }}>
                      <Input
                        innerRef={searchInputRef}
                        type="text"
                        placeholder={t('producteur.searchPlaceholder')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={`ps-4 ${isSearching ? 'searching' : ''}`}
                      />
                      <FaSearch
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '12px',
                          color: '#6c757d'
                        }}
                      />
                    </div>

                    <Input
                      type="date"
                      name="startDate"
                      value={dateFilters.startDate}
                      onChange={handleFilterChange}
                      placeholder={t('common.startDate')}
                      className="w-auto"
                    />

                    <Input
                      type="date"
                      name="endDate"
                      value={dateFilters.endDate}
                      onChange={handleFilterChange}
                      placeholder={t('common.endDate')}
                      className="w-auto"
                    />

                    <Button color="success" onClick={handleSearch}>
                      <FaSearch />
                    </Button>
                    <Button color="secondary" onClick={handleReset}>
                      <FaRedo />
                    </Button>
                    <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                      <FaFileExcel className="me-2" /> {t('campagne.download')}
                    </Button>
                  </div>
                  <div className="btn-group me-2">
                    <Button
                      color={viewMode === 'table' ? 'success' : 'light'}
                      onClick={() => setViewMode('table')}
                    >
                      <FaTable className="me-1" />
                      {t('common.tableView')}
                    </Button>
                    <Button
                      color={viewMode === 'card' ? 'success' : 'light'}
                      onClick={() => setViewMode('card')}
                    >
                      <FaThLarge className="me-1" />
                      {t('common.cardView')}
                    </Button>
                  </div>

                </div>
                <div className="cart-container" style={{
                  height: '400px',
                  overflowY: 'auto'
                }}>
                  {viewMode === 'table' ? (
                    <Table hover bordered className="align-middle mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th className="align-middle">
                            <FaImage className="me-2 text-success" size={12} />
                            {t('producteur.photo')}
                          </th>
                          <th className="align-middle">
                            <FaCalendarTimes className="me-2 text-success" size={12} />
                            {t('producteur.dateEnregist')}
                          </th>
                          <th className="align-middle text-end">
                            <FaTag className="me-2 text-success" size={12} />
                            {t('producteur.codeProducteur')}
                          </th>
                          <th className="align-middle text-end">
                            <FaTag className="me-2 text-success" size={12} />
                            {t('producteur.fullname')}
                          </th>
                          <th className="align-middle text-end">
                            <FaMap className="me-2 text-success" size={12} />
                            {t('producteur.emplacement')}
                          </th>
                          <th className="align-middle text-center" style={{ width: '120px' }}>
                            <FaCog className="me-2 text-success" size={12} />
                            {t('common.actions')}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {!producteurs || producteurs.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="text-center py-4">
                              <div className="text-muted">
                                {t('producteur.noProducteurs')}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          producteurs.map((producteur) => (
                            <tr key={producteur.id}>
                              <td className="align-middle">
                                {<React.Fragment>
                                  <img
                                    src={`${config.baseURL}${producteur.imageServerUrl}`}
                                    alt="Producteur"
                                    className="img-thumbnail profile-picture"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "30%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setSelectedImage(
                                        `${config.baseURL}${producteur.imageServerUrl}`
                                      );
                                      toggleModal();
                                    }}
                                  />
                                </React.Fragment>}
                              </td>
                              <td className="align-middle">{producteur.createdDate}</td>
                              <td className="align-middle text-center">{producteur.codeProducteur}</td>
                              <td className="align-middle text-end">{producteur.nomPrenoms}</td>
                              <td className="align-middle text-end">{
                                <React.Fragment>
                                  {districtDescription(producteur) + " / " + regionDescription(producteur) + " / " + zoneDescription(producteur) + " / " + prefectureDescription(producteur) + " / " + sousPrefectureDescription(producteur)}
                                </React.Fragment>
                              }
                              </td>
                              <td className="align-middle text-center">
                                <Button color="warning" size="sm" className="me-1 px-1 py-1" onClick={() => handleEdit(producteur)} >
                                  <FaEdit size={12} />
                                </Button>
                                <Button
                                  color="info"
                                  size="sm"
                                  className="me-1 px-1 py-1"
                                  onClick={() => handleParcelles(producteur)}
                                >
                                  <FaMapMarked size={12} />
                                </Button>
                                <Button color="danger" size="sm" className="px-1 py-1" onClick={() => handleDelete(producteur.id)} >
                                  <FaTrash size={12} />
                                </Button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <Row>
                      {!producteurs || producteurs.length === 0 ? (
                        <Col>
                          <div className="text-center py-4 text-muted">
                            {t('producteur.noProducteurs')}
                          </div>
                        </Col>
                      ) : (
                        producteurs.map((producteur) => (
                          <Col key={producteur.id} lg="2_4" md={3} className="mb-3">
                            <Card className="h-100 shadow-sm">
                              <div className="position-relative">
                                <CardImg
                                  top
                                  src={`${config.baseURL}${producteur.imageServerUrl}`}
                                  alt={producteur.nomPrenoms}
                                  style={{
                                    height: '150px', // Reduced height
                                    objectFit: 'cover',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setSelectedImage(`${config.baseURL}${producteur.imageServerUrl}`);
                                    toggleModal();
                                  }}
                                />
                                <div className="position-absolute top-0 end-0 m-2 badge bg-success">
                                  {producteur.codeProducteur}
                                </div>
                              </div>
                              <CardBody className="p-2"> {/* Reduced padding */}
                                <h6 className="mb-2">{producteur.nomPrenoms}</h6> {/* Changed from h5 to h6 */}
                                <p className="text-muted small mb-2">
                                  <FaCalendarAlt className="me-1" size={10} /> {/* Reduced icon size */}
                                  {producteur.createdDate}
                                </p>
                                <p className="text-muted small mb-2">
                                  <FaMapMarkerAlt className="me-1" size={10} /> {/* Reduced icon size */}
                                  {districtDescription(producteur)}
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <Button color="success" size="sm" className="me-1" onClick={() => handleParcelles(producteur)}>
                                      <FaMapMarked size={12} className="me-1" />
                                      {t('parcelle.manage')}
                                    </Button>
                                    <Button color="warning" size="sm" className="me-1" onClick={() => handleEdit(producteur)}>
                                      <FaEye size={12} className="me-1" />
                                    </Button>

                                    <Button color="danger" size="sm" className="me-1" onClick={() => handleDelete(producteur.id)}>
                                      <FaTrash size={12} className="me-1" />

                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                      )}
                    </Row>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <small className="text-muted">
                    {t('common.showing', {
                      start: (currentPage - 1) * pageSize + 1,
                      end: Math.min(currentPage * pageSize, totalElements),
                      total: totalElements,
                      currentPage: currentPage,
                      totalPages: totalPages
                    })}
                  </small>
                  <Pagination size="sm" className="mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink first onClick={() => handlePageChange(1)}>
                        <FaAngleDoubleLeft size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                        <FaAngleLeft size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                        <FaAngleRight size={12} />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink last onClick={() => handlePageChange(totalPages)}>
                        <FaAngleDoubleRight size={12} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </>)}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => setEditModal(false)} size='xl'>
        <ModalHeader toggle={() => setEditModal(false)}>
          {t('producteur.ficheProducteur')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-success me-2" />
                  <h5 className="mb-0">{t("Informations Personnelles")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.codeProducteur')}</Label>
                      <Input
                        value={selectedProducteur.codeProducteur}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, codeProducteur: e.target.value })}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nomPrenoms')}</Label>
                      <Input
                        value={selectedProducteur.nomPrenoms}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, nomPrenoms: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.dateNaissance')}</Label>
                      <Input
                        type='date'
                        value={selectedProducteur.dateNaissance}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, dateNaissance: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.lieuNaissance')}</Label>
                      <Input
                        value={selectedProducteur.lieuNaissance}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, lieuNaissance: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nomPere')}</Label>
                      <Input
                        value={selectedProducteur.nomPere}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, nomPere: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nomMere')}</Label>
                      <Input
                        value={selectedProducteur.nomMere}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, nomMere: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup tag="fieldset">
                      <Label>{t('producteur.genre')}</Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="genre"
                            value="HOMME"
                            checked={selectedProducteur.genre === 'HOMME'}
                            onChange={(e) => setSelectedProducteur({ ...selectedProducteur, genre: e.target.value })}
                          />{' '}
                          {t('producteur.homme')}
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="genre"
                            value="FEMME"
                            checked={selectedProducteur.genre === 'FEMME'}
                            onChange={(e) => setSelectedProducteur({ ...selectedProducteur, genre: e.target.value })}
                          />{' '}
                          {t('producteur.femme')}
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.numeroTelephone')}</Label>
                      <Input
                        value={selectedProducteur.numeroTelephone}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, numeroTelephone: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.numeroCmu')}</Label>
                      <Input
                        value={selectedProducteur.numeroCmu}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, numeroCmu: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.numeroCmu')}</Label>
                      <Input
                        value={selectedProducteur.numeroCmu}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, numeroCmu: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.typePieceIdentification')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.typePieceIdentification || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          typePieceIdentification: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        <option value="CNI">CNI</option>
                        <option value="PASSPORT">{t('producteur.passport')}</option>
                        <option value="SEJOUR">{t('producteur.carteSejourLibelle')}</option>
                        <option value="EXTRAIT">{t('producteur.extraitNaissance')}</option>
                        <option value="CONSULAIRE">{t('producteur.carteConsulaire')}</option>
                        <option value="AUCUN">{t('common.none')}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.numeroPiece')}</Label>
                      <Input
                        value={selectedProducteur.numeroPiece}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, numeroPiece: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.dateEtablissementPiece')}</Label>
                      <Input
                        type='date'
                        value={selectedProducteur.dateEtablissementPiece}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, dateEtablissementPiece: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.ethnie')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.ethnie || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          ethnie: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.ethnies || []).map((ethnie) => (
                          <option key={ethnie.id} value={ethnie.id}>
                            {ethnie.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.origine')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.origine || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          origine: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        <option value="AUTHOCHTONE">Autochtone</option>
                        <option value="ALLOGENE">Allogène</option>
                        <option value="ALLOCHTONE">Allochtone</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.situationMatrimoniale')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.situationMatrimoniale || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          situationMatrimoniale: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        <option value="CELEBATAIRE">Célibataire</option>
                        <option value="MARIE">Marié(e)</option>
                        <option value="DIVORCE">Divorcé(e)</option>
                        <option value="VEUF">Veuf(ve)</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nationalite')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.nationalite || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          nationalite: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.nationalities || []).map((item) => (
                          <option key={item.code} value={item.description}>
                            {item.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nombreFemmes')}</Label>
                      <Input
                        type='number'
                        value={selectedProducteur.nombreFemmes}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, nombreFemmes: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.nombreEnfants')}</Label>
                      <Input
                        type='number'
                        value={selectedProducteur.nombreEnfants}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, nombreEnfants: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.niveauEtude')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.niveauEtude || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          niveauEtude: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        <option value="PRIMAIRE">Primaire</option>
                        <option value="SECONDAIRE">Secondaire</option>
                        <option value="UNIVERSITAIRE">Universitaire</option>
                        <option value="AUCUN">Aucun</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-success me-2" />
                  <h5 className="mb-0">{t("Localisation géographique")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.campagne')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.campagneId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          campagneId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.campagnes || []).map((campagne) => (
                          <option key={campagne.id} value={campagne.id}>
                            {campagne.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.district')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.districtId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          districtId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.districts || []).map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.region')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.regionId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          regionId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.regions || []).map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.zone')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.zoneId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          zoneId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.zones || []).map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.prefecture')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.prefectureId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          prefectureId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.perfectures || []).map((prefecture) => (
                          <option key={prefecture.id} value={prefecture.id}>
                            {prefecture.nomPrefecture}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.sousPerfecture')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.sousPrefectureId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          sousPrefectureId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.sousPerfectures || []).map((sous) => (
                          <option key={sous.id} value={sous.id}>
                            {sous.nomSousPrefecture}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.localite')}</Label>
                      <Input
                        value={selectedProducteur.localite}
                        onChange={(e) => setSelectedProducteur({ ...selectedProducteur, localite: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-success me-2" />
                  <h5 className="mb-0">{t("Détails Agricoles")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.cooperative')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.cooperativeId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          cooperativeId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.cooperatives || []).map((cooperative) => (
                          <option key={cooperative.id} value={cooperative.id}>
                            {cooperative.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>{t('producteur.faitiere')}</Label>
                      <Input
                        type="select"
                        value={selectedProducteur.faitiereId || ''}
                        onChange={(e) => setSelectedProducteur({
                          ...selectedProducteur,
                          faitiereId: e.target.value
                        })}
                      >
                        <option value="">{t('common.select')}</option>
                        {(masterData.faitieres || []).map((faitiere) => (
                          <option key={faitiere.id} value={faitiere.id}>
                            {faitiere.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label>{t("Cultures Agricoles")}</Label>
                    <Select
                      isMulti
                      options={masterData.culturesAgricoles ?? [].map((culture) => ({
                        value: culture.id,
                        label: culture.nomCulture,
                      }))}
                      onChange={handleCulturesSelection}
                      placeholder={t("Sélectionner")}
                      value={selectedProducteur.cultures ?? [].map((culture) => ({
                        value: culture.cultureAgricoleId,
                        label: culture.nomCulture,
                      }))}
                    />
                  </Col>
                  <Col md={12}>
                    {selectedProducteur.cultures?.map((culture, index) => (
                      <div key={index} className="mt-4">
                        <h5>{`Détails pour ${culture.nomCulture}`}</h5>
                        <Row>
                          <Col md={3}>
                            <Label>{t("Nombre de Parcelles")}</Label>
                            <Input
                              name={`cultures[${index}].nombreParcelles`}
                              label={t("Nombre de Parcelles")}
                              type="number"
                              value={culture.nombreParcelles || 0}
                              onChange={(e) =>
                                setSelectedProducteur({
                                  ...selectedProducteur,
                                  cultures: selectedProducteur.cultures.map((item, i) =>
                                    i === index
                                      ? { ...item, nombreParcelles: parseInt(e.target.value, 10) || 0 }
                                      : item
                                  )
                                }
                                )
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>{t("Surface en Hectares")}</Label>
                            <Input
                              name={`cultures[${index}].surfaceEnHectare`}
                              label={t("Surface en Hectares")}
                              type="number"
                              step="0.01"
                              value={culture.surfaceEnHectare}
                              onChange={(e) =>
                                setSelectedProducteur({
                                  ...selectedProducteur,
                                  cultures: selectedProducteur.cultures.map((item, i) =>
                                    i === index
                                      ? { ...item, surfaceEnHectare: parseFloat(e.target.value) || 0 }
                                      : item
                                  )
                                }
                                )
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>{t("Production Estimée (Kg)")}</Label>
                            <Input
                              name={`cultures[${index}].productionEstimeeKg`}
                              label={t("Production Estimée (Kg)")}
                              type="number"
                              step="0.01"
                              value={culture.productionEstimeeKg}
                              onChange={(e) =>
                                setSelectedProducteur({
                                  ...selectedProducteur,
                                  cultures: selectedProducteur.cultures.map((item, i) =>
                                    i === index
                                      ? { ...item, productionEstimeeKg: parseFloat(e.target.value) || 0 }
                                      : item
                                  )
                                }
                                )
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>{t("Production Estimée (Kg)")}</Label>
                            <Input
                              name={`cultures[${index}].chiffreAffaireEstime`}
                              label={t("Chiffre d'Affaires Estimé")}
                              type="number"
                              step="0.01"
                              value={culture.chiffreAffaireEstime}
                              onChange={(e) =>
                                setSelectedProducteur({
                                  ...selectedProducteur,
                                  cultures: selectedProducteur.cultures.map((item, i) =>
                                    i === index
                                      ? { ...item, chiffreAffaireEstime: parseFloat(e.target.value) || 0 }
                                      : item
                                  )
                                }
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-success me-2" />
                  <h5 className="mb-0">{t("Géolocalisation")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ width: "100%", height: "60vh", margin: 0, padding: 0 }}>
                  {coordinates.latitude && coordinates.longitude ? (
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{
                        border: 0,
                        margin: 0,
                        padding: 0,
                        //position: "absolute", // Ensures it covers the full screen
                        top: 0,
                        left: 0,
                      }}
                      src={`https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&hl=es;z=14&output=embed`}
                      allowFullScreen
                      title="Google Map"
                    ></iframe>
                  ) : (
                    <p style={{ textAlign: "center", marginTop: "50%" }}>
                      {t("Géolocalisation non disponible pour ce producteur.")}
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleUpdate}>
            {t('common.save')}
          </Button>
          <Button color="secondary" onClick={() => setEditModal(false)}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
      {/* Modal for Image Zoom */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} size='sm'>
        <ModalBody>
          <img
            src={selectedImage}
            alt="Zoomed Producteur"
            className="img-fluid"
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={parcelleModal} toggle={() => setParcelleModal(false)} size="xl">
        <ModalHeader toggle={() => setParcelleModal(false)}>
          {t('parcelle.title')} - {selectedProducteur.codeProducteur}
        </ModalHeader>
        <ModalBody>
          <Card className="mb-4">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{t('parcelle.list')}</h5>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => setNewParcelleModal(true)}
                >
                  <FaPlus className="me-2" />
                  {t('parcelle.new')}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover bordered responsive>
                <thead>
                  <tr>
                    <th>{t('parcelle.codeParcelle')}</th>
                    <th>{t('parcelle.campagne')}</th>
                    <th>{t('parcelle.culture')}</th>
                    <th>{t('parcelle.surfaceGps')} (ha)</th>
                    <th>{t('parcelle.surfaceReelle')} (ha)</th>
                    <th>{t('common.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedParcelles.map(parcelle => (
                    <tr key={parcelle.id}>
                      <td>{parcelle.codeParcelle}</td>
                      <td>{getCampaignDescriptionById(parcelle.campagneId)}</td>
                      <td>{getCultureById(parcelle.cultureAgricoleId)}</td>
                      <td>{parcelle.surfaceGps}</td>
                      <td>{parcelle.surfaceReelle}</td>
                      <td>
                        <Button color="info" size="sm" className="me-1" onClick={() => handleEditParcelle(parcelle)}>
                          <FaEdit size={12} />
                        </Button>
                        <Button color="danger" size="sm" onClick={() => handleDeleteParcelle(parcelle.id)}>
                          <FaTrash size={12} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {/* New Parcelle Modal */}
      <Modal
        isOpen={newParcelleModal}
        toggle={handleParcelleModalClose}
        size="xl"
      >
        <ModalHeader toggle={handleParcelleModalClose}>
          {t('parcelle.new')}
        </ModalHeader>
        <Form onSubmit={handleParcelleSubmit}>
          <ModalBody>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.campagne')}</Label>
                  <Input
                    type="select"
                    value={newParcelle.campagneId}
                    onChange={(e) => setNewParcelle({ ...newParcelle, campagneId: e.target.value })}
                    required
                  >
                    <option value="">{t('common.select')}</option>
                    {masterData.campagnes?.map(campagne => (
                      <option key={campagne.id} value={campagne.id}>
                        {campagne.description}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.codeParcelle')}</Label>
                  <Input
                    type="text"
                    value={newParcelle.codeParcelle}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.dateCreation')}</Label>
                  <Input
                    type="date"
                    value={newParcelle.dateCreation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.cultureAgricole')}</Label>
                  <Input
                    type="select"
                    value={newParcelle.cultureAgricoleId}
                    onChange={(e) => setNewParcelle({ ...newParcelle, cultureAgricoleId: e.target.value })}
                    required
                  >
                    <option value="">{t('common.select')}</option>
                    {masterData.culturesAgricoles?.map(culture => (
                      <option key={culture.id} value={culture.id}>
                        {culture.nomCulture}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>{t('parcelle.culturesEnvironnantes')}</Label>
                  <Input
                    type="textarea"
                    value={newParcelle.culturesEnvironnantes}
                    onChange={(e) => setNewParcelle({ ...newParcelle, culturesEnvironnantes: e.target.value })}
                    rows={3}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.varieteSemences')}</Label>
                  <Input
                    type="select"
                    value={newParcelle.varieteSemenceId}
                    onChange={(e) => setNewParcelle({ ...newParcelle, varieteSemenceId: e.target.value })}
                    required
                  >
                    <option value="">{t('common.select')}</option>
                    {masterData.varietes?.map(variete => (
                      <option key={variete.id} value={variete.id}>
                        {variete.nomVariete}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('parcelle.dateSemis')}</Label>
                  <Input
                    type="date"
                    value={newParcelle.dateSemis}
                    onChange={(e) => setNewParcelle({ ...newParcelle, dateSemis: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Card className="mt-4">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <FaMapMarkerAlt className="text-success me-2" />
                    <h5 className="mb-0">{t('parcelle.geolocation')}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div style={{ height: '400px', width: '100%', position: 'relative' }}>
                        {isLoaded &&
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={{
                              lat: parcelleCoordinates.latitude || DEFAULT_COORDINATES.latitude,
                              lng: parcelleCoordinates.longitude || DEFAULT_COORDINATES.longitude
                            }}
                            zoom={15}
                            onClick={(e) => {
                              if (e.latLng) {
                                const coords = {
                                  latitude: e.latLng.lat(),
                                  longitude: e.latLng.lng()
                                };
                                setParcelleCoordinates(coords);
                                setNewParcelle({
                                  ...newParcelle,
                                  latitude: coords.latitude,
                                  longitude: coords.longitude
                                });
                              }
                            }}
                            options={{
                              streetViewControl: false,
                              mapTypeControl: true,
                              mapTypeControlOptions: {
                                style: window.google?.maps.MapTypeControlStyle.DROPDOWN_MENU,
                                position: window.google?.maps.ControlPosition.TOP_RIGHT
                              },
                              zoomControl: true,
                              zoomControlOptions: {
                                position: window.google?.maps.ControlPosition.RIGHT_TOP
                              }
                            }}
                          >
                            {parcelleCoordinates.latitude && parcelleCoordinates.longitude && (
                              <Marker
                                position={{
                                  lat: parcelleCoordinates.latitude,
                                  lng: parcelleCoordinates.longitude
                                }}
                                draggable={true}
                                onDragEnd={(e) => {
                                  if (e.latLng) {
                                    const coords = {
                                      latitude: e.latLng.lat(),
                                      longitude: e.latLng.lng()
                                    };
                                    setParcelleCoordinates(coords);
                                    setNewParcelle({
                                      ...newParcelle,
                                      latitude: coords.latitude,
                                      longitude: coords.longitude
                                    });
                                  }
                                }}
                              />
                            )}
                            {newParcelle.gpxCoordinates && (
                              <Polygon
                                paths={newParcelle.gpxCoordinates}
                                options={{
                                  fillColor: '#198754',
                                  fillOpacity: 0.35,
                                  strokeColor: '#198754',
                                  strokeWeight: 2
                                }}
                              />
                            )}
                          </GoogleMap>
                        }
                        <div
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1000
                          }}
                        >
                          <Button
                            color="success"
                            size="sm"
                            onClick={() => {
                              if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(
                                  (position) => {
                                    const coords = {
                                      latitude: position.coords.latitude,
                                      longitude: position.coords.longitude
                                    };
                                    setParcelleCoordinates(coords);
                                    setNewParcelle({
                                      ...newParcelle,
                                      latitude: coords.latitude,
                                      longitude: coords.longitude
                                    });
                                  },
                                  (error) => {
                                    console.error("Error getting location:", error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: t('parcelle.locationError'),
                                      text: t('parcelle.enableLocation')
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            <FaMapMarkerAlt className="me-2" />
                            {t('parcelle.getCurrentLocation')}
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.latitude')}</Label>
                        <Input
                          type="number"
                          step="any"
                          value={parcelleCoordinates.latitude}
                          onChange={(e) => {
                            const lat = parseFloat(e.target.value);
                            if (!isNaN(lat)) {
                              setParcelleCoordinates({ ...parcelleCoordinates, latitude: lat });
                              setNewParcelle({ ...newParcelle, latitude: lat });
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.longitude')}</Label>
                        <Input
                          type="number"
                          step="any"
                          value={parcelleCoordinates.longitude}
                          onChange={(e) => {
                            const lng = parseFloat(e.target.value);
                            if (!isNaN(lng)) {
                              setParcelleCoordinates({ ...parcelleCoordinates, longitude: lng });
                              setNewParcelle({ ...newParcelle, longitude: lng });
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.surfaceGps')}</Label>
                        <Input
                          type="number"
                          step="0.01"
                          value={newParcelle.surfaceGps}
                          onChange={(e) => setNewParcelle({ ...newParcelle, surfaceGps: parseFloat(e.target.value) })}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.surfaceReelle')}</Label>
                        <Input
                          type="number"
                          step="0.01"
                          value={newParcelle.surfaceReelle}
                          onChange={(e) => setNewParcelle({ ...newParcelle, surfaceReelle: parseFloat(e.target.value) })}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.productionReelle')}</Label>
                        <Input
                          type="number"
                          step="0.01"
                          value={newParcelle.productionReelle}
                          onChange={(e) => setNewParcelle({ ...newParcelle, productionReelle: parseFloat(e.target.value) })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-3">
                      <FormGroup>
                        <Label>{t('parcelle.rendementReel')}</Label>
                        <Input
                          type="number"
                          step="0.01"
                          value={newParcelle.rendementReel}
                          onChange={(e) => setNewParcelle({ ...newParcelle, rendementReel: parseFloat(e.target.value) })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card className="mt-4">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <FaFileUpload className="text-success me-2" />
                    <h5 className="mb-0">{t('parcelle.gpxFile')}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>{t('parcelle.importGpx')}</Label>
                        <div className="d-flex align-items-center gap-2">
                          <Input
                            type="file"
                            accept=".gpx"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (event) => {
                                  try {
                                    const parser = new GpxParser(); // Create new parser instance
                                    parser.parse(event.target.result);

                                    const coordinates = [];

                                    // Extract points from tracks
                                    if (parser.tracks.length > 0) {
                                      parser.tracks.forEach(track => {
                                        track.points.forEach(point => {
                                          coordinates.push({
                                            lat: point.lat,
                                            lng: point.lon
                                          });
                                        });
                                      });
                                    }

                                    if (coordinates.length > 0) {
                                      // Calculate area in square meters
                                      const area = calculatePolygonArea(coordinates);

                                      // Convert to hectares (1 hectare = 10000 m²)
                                      const areaInHectares = area / 10000;

                                      // Update form state
                                      setNewParcelle({
                                        ...newParcelle,
                                        surfaceGps: areaInHectares.toFixed(4),
                                        surfaceReelle: areaInHectares.toFixed(4),
                                        gpxCoordinates: coordinates
                                      });

                                      // Update map to show polygon
                                      setParcelleCoordinates({
                                        latitude: coordinates[0].lat,
                                        longitude: coordinates[0].lng
                                      });

                                      // Show success message
                                      Swal.fire({
                                        icon: 'success',
                                        title: t('parcelle.gpxImported'),
                                        text: t('parcelle.surfaceCalculated', { surface: areaInHectares.toFixed(4) }),
                                        confirmButtonColor: '#198754'
                                      });
                                    } else {
                                      throw new Error('No valid coordinates found in GPX file');
                                    }
                                  } catch (error) {
                                    console.error('Error parsing GPX file:', error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: t('parcelle.gpxError'),
                                      text: t('parcelle.invalidGpxFile'),
                                      confirmButtonColor: '#dc3545'
                                    });
                                  }
                                };
                                reader.readAsText(file);
                              }
                            }}
                          />
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={() => {
                              setNewParcelle({
                                ...newParcelle,
                                surfaceGps: 0,
                                gpxCoordinates: null
                              });
                            }}
                          >
                            {t('common.reset')}
                          </Button>
                        </div>
                        <small className="text-muted">
                          {t('parcelle.gpxHelp')}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card className="mt-4">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <FaImage className="text-success me-2" />
                    <h5 className="mb-0">{t('parcelle.parcelleImages')}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>{t('parcelle.uploadImages')}</Label>
                        <Input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageUpload}
                        />
                        <small className="text-muted">
                          {t('parcelle.max3Images2MB')}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {parcelleImages.map((image, index) => (
                      <Col md={4} key={index} className="mt-2">
                        <Card>
                          <CardBody>
                            {image?.imageUrl ?
                              <img
                                src={`${config.baseURL}${image.imageUrl}`}
                                className="img-fluid"
                              />
                              :
                              <img
                                src={image.data}
                                alt={image.name}
                                className="img-fluid"
                              />}
                            <Button
                              color="danger"
                              size="sm"
                              className="mt-2"
                              onClick={() => removeImage(index)}
                            >
                              {t('common.remove')}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit" disabled={newParcelle.codeParcelle}>
              {t('common.save')}
            </Button>
            <Button color="secondary" onClick={handleParcelleModalClose}>
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>

  );
};

export default ProducteurList;
