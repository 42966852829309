import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';
import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { typePieceActions } from '../../../sagas/typePieceSlice';

const TypePieces = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    typePieces,
    isFetching,
    totalPages,
    totalElements,
    pageNumber,
    pageSize,
    error,
  } = useSelector((state) => state.typePieces);
  const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page

  const [modal, setModal] = useState(false);
  const [selectedTypePiece, setSelectedTypePiece] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    libelle: '',
    active: 'Y'
  });

  useEffect(() => {
    dispatch(typePieceActions.search({ page: currentPage, size: pageSize }));
  }, [currentPage]);

  const toggle = () => {
    setModal(!modal);
    if (modal) {
      setFormData({ code: '', libelle: '', active: 'Y' });
      setSelectedTypePiece(null);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      dto: formData,
      id: selectedTypePiece?.id,
      onSuccess: () => {
        toggle();
        Swal.fire({
          title: t(selectedTypePiece ? 'culture.update.successTitle' : 'culture.create.successTitle'),
          text: t(selectedTypePiece ? 'culture.update.successText' : 'culture.create.successText'),
          icon: 'success',
          timer: 1500
        });
        dispatch(typePieceActions.search({ page: 0, size: 10 }));
      },
      onError: (error) => {
        Swal.fire({
          title: t(selectedTypePiece ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
          text: error.message || t(selectedTypePiece ? 'culture.update.errorText' : 'culture.create.errorText'),
          icon: 'error'
        });
      }
    };

    dispatch(selectedTypePiece ?
      typePieceActions.update(payload) :
      typePieceActions.create(payload)
    );
  };

  const handleEdit = (typePiece) => {
    setSelectedTypePiece(typePiece);
    setFormData({
      code: typePiece.code,
      libelle: typePiece.libelle,
      active: typePiece.active
    });
    toggle();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t('culture.delete.title'),
      text: t('culture.delete.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('culture.delete.confirm')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(typePieceActions.delete({
          id,
          onSuccess: () => {
            Swal.fire({
              title: t('culture.delete.successTitle'),
              text: t('culture.delete.successText'),
              icon: 'success',
              timer: 1500
            });
            dispatch(typePieceActions.search({ page: 0, size: pageSize }))
          },
          onError: (error) => {
            Swal.fire({
              title: t('culture.delete.errorTitle'),
              text: error.message || t('culture.delete.errorText'),
              icon: 'error'
            });
          }

        }));
      }
    });
  };

  const handleExport = async () => {
    Swal.fire({
      title: t('typePiece.export.loading'),
      text: t('typePiece.export.wait'),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let payload = {
      onSuccess: (data) => {
        const excelData = data.map(typePiece => ({
          'Code': typePiece.codeType,
          'Description': typePiece.description,
          'Statut': typePiece.actif === 'Y' ? 'Actif' : 'Inactif'
        }));

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Types de Pièces');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const fileName = `types-pieces_${new Date().toISOString().split('T')[0]}.xlsx`;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();

        Swal.fire({
          title: t('typePiece.export.successTitle'),
          text: t('typePiece.export.successText'),
          icon: 'success',
          timer: 1500
        });
      },
      onError: (error) => {
        Swal.fire({
          title: t('typePiece.export.errorTitle'),
          text: t('typePiece.export.errorText'),
          icon: 'error'
        });
      }
    };

    dispatch(typePieceActions.findAll(payload));
  };


  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h4 className="card-title mb-1">{t('typePiece.title')}</h4>
            <p className="text-muted small mb-0">{t('typePiece.description')}</p>
          </div>
          <div className="d-flex gap-2">
            <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
              <FaFileExcel className="me-2" /> {t('common.download')}
            </Button>
            <Button color="success" className="d-flex align-items-center" onClick={toggle}>
              <FaPlus className="me-2" /> {t('typePiece.new')}
            </Button>
          </div>
        </div>

        {isFetching ? (
          <div className="text-center py-5">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">{t('common.loading')}</span>
            </div>
            <div className="mt-2 text-muted">
              {t('common.loadingData')}
            </div>
          </div>
        ) : (<>
          <div className="table-responsive">
            <Table hover bordered className="align-middle mb-0">
              <thead>
                <tr>
                  <th>{t('typePiece.code')}</th>
                  <th>{t('typePiece.libelle')}</th>
                  <th>{t('typePiece.status')}</th>
                  <th>{t('typePiece.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {typePieces.map(typePiece => (
                  <tr key={typePiece.id}>
                    <td>{typePiece.code}</td>
                    <td>{typePiece.libelle}</td>
                    <td>
                      <Badge color={typePiece.active === 'Y' ? "success" : "secondary"}>
                        {typePiece.active === 'Y' ? t('typePiece.active') : t('typePiece.inactive')}
                      </Badge>
                    </td>
                    <td>
                      <Button color="info" size="sm" className="me-2"
                        onClick={() => handleEdit(typePiece)}>
                        <FaEdit />
                      </Button>
                      <Button color="danger" size="sm"
                        onClick={() => handleDelete(typePiece.id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <small className="text-muted">
              {t('actions.showing', {
                start: (currentPage) * pageSize + 1,
                end: Math.min((currentPage + 1) * pageSize, totalElements),
                total: totalElements,
                currentPage: currentPage,
                totalPages: totalPages
              })}
            </small>
            <Pagination size="sm" className="mb-0">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink first onClick={() => handlePageChange(0)}>
                  <FaAngleDoubleLeft size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                  <FaAngleLeft size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages - 1}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                  <FaAngleRight size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages - 1}>
                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                  <FaAngleDoubleRight size={12} />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        </>
        )}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {selectedTypePiece ? t('typePiece.edit') : t('typePiece.new')}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="libelle">{t('typePiece.libelle')}</Label>
                <Input
                  id="libelle"
                  name="libelle"
                  value={formData.libelle}
                  onChange={e => setFormData({ ...formData, libelle: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="active">{t('typePiece.status')}</Label>
                <Input
                  type="select"
                  id="active"
                  name="active"
                  value={formData.active}
                  onChange={e => setFormData({ ...formData, active: e.target.value })}
                >
                  <option value="Y">{t('typePiece.active')}</option>
                  <option value="N">{t('typePiece.inactive')}</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>{t('typePiece.cancel')}</Button>
              <Button color="success" type="submit">{t('typePiece.save')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default TypePieces;