import React, { useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Container, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import Campagnes from './tabs/Campagnes';
import Districts from './tabs/Districts';
import CulturesAgricoles from './tabs/Cultures';
import VarietesSemences from './tabs/VarieteSemences';
import TypePieces from './tabs/TypePieces';
import { useTranslation } from 'react-i18next';
import { FaBoxes, FaBuilding, FaCity, FaDatabase, FaGlobe, FaHandshake, FaHome, FaIdCard, FaLayerGroup, FaLeaf, FaMap, FaMapMarkerAlt, FaPeopleCarry, FaSeedling, FaUsers } from 'react-icons/fa';
import Regions from './tabs/Regions';
import Prefectures from './tabs/Prefectures';
import SousPrefectures from './tabs/SousPrefectures';
import Zones from './tabs/Zones';
import Sections from './tabs/Sections';
import Faitieres from './tabs/Faitieres';
import Cooperatives from './tabs/Cooperatives';
import Ethnies from './tabs/Ethnies';
import "../../assets/css/navs.scss";

const DatabaseTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Card className="shadow-sm border-0">
            <CardBody className="py-3">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left side - Title */}
                <h4 className="mb-0 d-flex align-items-center">
                  <FaDatabase className="text-success me-2" />
                  {t('database.pageTitle')}
                </h4>
                {/* Right side - Breadcrumb */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard" className="text-decoration-none">
                        {t('nav.dashboard')}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t('nav.basedata')}
                    </li>
                  </ol>
                </nav>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={3} className="pe-4">
          <Card className="shadow-sm h-100">
            <CardBody>
              <div className="vertical-nav-wrapper">
                <Nav vertical pills className="flex-column gap-2 vertical-nav">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('1')}
                    >
                      <FaSeedling className="me-2" /> {t('database.tabs.campaigns')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('2')}
                    >
                      <FaLeaf className="me-2" /> {t('database.tabs.cultures')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('3')}
                    >
                      <FaBoxes className="me-2" /> {t('database.tabs.varieties')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('4')}
                    >
                      <FaIdCard className="me-2" /> {t('database.tabs.documents')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('5')}
                    >
                      <FaMapMarkerAlt className="me-2" /> {t('database.tabs.districts')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('6')}
                    >
                      <FaGlobe className="me-2" /> {t('database.tabs.regions')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '7' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('7')}
                    >
                      <FaCity className="me-2" /> {t('database.tabs.prefectures')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '8' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('8')}
                    >
                      <FaBuilding className="me-2" /> {t('database.tabs.subPrefectures')}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '9' }, "d-flex align-items-center p-3")}
                        onClick={() => toggle('9')}
                      >
                        <FaHome className="me-2" /> {t('database.tabs.localities')}
                      </NavLink>
                    </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '10' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('10')}
                    >
                      <FaMap className="me-2" /> {t('database.tabs.zones')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '11' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('11')}
                    >
                      <FaLayerGroup className="me-2" /> {t('database.tabs.sections')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '12' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('12')}
                    >
                      <FaHandshake className="me-2" /> {t('database.tabs.faitieres')}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '13' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('13')}
                    >
                      <FaUsers className="me-2" /> {t('database.tabs.cooperatives')}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '14' }, "d-flex align-items-center p-3")}
                      onClick={() => toggle('14')}
                    >
                      <FaPeopleCarry className="me-2" /> {t('database.tabs.ethnicGroups')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={9}>
          <div className="tab-content-wrapper p-3">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Campagnes />
              </TabPane>
              <TabPane tabId="2">
                <CulturesAgricoles />
              </TabPane>
              <TabPane tabId="3">
                <VarietesSemences />
              </TabPane>
              <TabPane tabId="4">
                <TypePieces />
              </TabPane>
              <TabPane tabId="5">
                <Districts />
              </TabPane>
              <TabPane tabId="6">
                <Regions />
              </TabPane>
              <TabPane tabId="7">
                <Prefectures />
              </TabPane>
              <TabPane tabId="8">
                <SousPrefectures />
              </TabPane>
              <TabPane tabId="10">
                <Zones />
              </TabPane>
              <TabPane tabId="11">
                <Sections />
              </TabPane>
              <TabPane tabId="12">
                <Faitieres />
              </TabPane>
              <TabPane tabId="13">
                <Cooperatives />
              </TabPane>
              <TabPane tabId="14">
                <Ethnies />
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default DatabaseTabs;