import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaMapMarkerAlt, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { regionActions } from '../../../sagas/regionSlice';
import { districtActions } from '../../../sagas/districtSlice';
import * as XLSX from 'xlsx';

const Regions = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        regions,
        isFetching: isFetchingRegions,
        totalPages: totalPagesRegions,
        totalElements: totalElementsRegions,
        pageNumber: pageNumberRegions,
        pageSize: pageSizeRegions,
        error: errorRegions,
    } = useSelector((state) => state.regions);
    const {
        districts,
        isFetching: isFetchingDistricts,
        totalPages: totalPagesDistricts,
        totalElements: totalElementsDistricts,
        pageNumber: pageNumberDistricts,
        pageSize: pageSizeDistricts,
        error: errorDistricts,
    } = useSelector((state) => state.districts);
    const [currentPage, setCurrentPage] = useState(pageNumberRegions); // Track current page
    const [currentPageDistrict, setCurrentPageDistrict] = useState(pageNumberDistricts); // Track current page

    const [modal, setModal] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [districtModal, setDistrictModal] = useState(false);
    const [formData, setFormData] = useState({
        codeRegion: '',
        nomRegion: '',
        codeDistrict: '',
        nomDistrict: '',
        active: 'Y',
        coordonneesGps: ''
    });

    useEffect(() => {
        dispatch(regionActions.search({ page: currentPage, size: pageSizeRegions }));
    }, [currentPage]);

    useEffect(() => {
        dispatch(districtActions.search({ page: currentPageDistrict, size: pageSizeDistricts }));
    }, [currentPageDistrict]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                codeRegion: '',
                nomRegion: '',
                codeDistrict: '',
                nomDistrict: '',
                active: 'Y',
                coordonneesGps: ''
            });
            setSelectedRegion(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleDistrictPageChange = (newPage) => {
        setCurrentPageDistrict(newPage);
    };

    const toggleDistrictModal = () => {
        setDistrictModal(!districtModal);
        if (!districtModal) {
            dispatch(districtActions.search({ page: pageNumberDistricts, size: pageSizeDistricts }));
        }
    };

    const handleDistrictSelect = (district) => {
        setFormData({
            ...formData,
            codeDistrict: district.codeDistrict,
            nomDistrict: district.nomDistrict
        });
        toggleDistrictModal();
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('region.export.loading'),
            text: t('region.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(region => ({
                    'Code Région': region.codeRegion,
                    'Nom Région': region.nomRegion,
                    'Code District': region.codeDistrict,
                    'Nom District': region.nomDistrict,
                    'Coordonnées GPS': region.coordonneesGps || '',
                    'Statut': region.active === 'Y' ? 'Actif' : 'Inactif'
                }));
    
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Regions');
    
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
    
                const fileName = `regions_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
    
                Swal.fire({
                    title: t('region.export.successTitle'),
                    text: t('region.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('region.export.errorTitle'),
                    text: t('region.export.errorText'),
                    icon: 'error'
                });
            }
        };
    
        dispatch(regionActions.findAll(payload));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedRegion?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedRegion ? 'culture.update.successTitle' : 'culture.create.successTitle'),
                    text: t(selectedRegion ? 'culture.update.successText' : 'culture.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(regionActions.search({ page: 0, size: pageSizeRegions }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedRegion ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
                    text: error.message || t(selectedRegion ? 'culture.update.errorText' : 'culture.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedRegion ?
            regionActions.update(payload) :
            regionActions.create(payload)
        );
    };

    const handleEdit = (region) => {
        setSelectedRegion(region);
        setFormData({
            codeRegion: region.codeRegion,
            nomRegion: region.nomRegion,
            codeDistrict: region.codeDistrict,
            nomDistrict: region.nomDistrict,
            active: region.active,
            coordonneesGps: region.coordonneesGps
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('culture.delete.title'),
            text: t('culture.delete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('culture.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(regionActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('culture.delete.successTitle'),
                            text: t('culture.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(regionActions.search({ page: 0, size: pageSizeRegions }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('culture.delete.errorTitle'),
                            text: error.message || t('culture.delete.errorText'),
                            icon: 'error'
                        });
                    }

                }));
            }
        });
    };

    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('region.title')}</h4>
                        <p className="text-muted small mb-0">{t('region.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="success" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('region.new')}
                        </Button>
                    </div>
                </div>

                {isFetchingRegions ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-success" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('region.code')}</th>
                                    <th>{t('region.name')}</th>
                                    <th>{t('region.district')}</th>
                                    <th>{t('region.coordinates')}</th>
                                    <th>{t('region.status')}</th>
                                    <th>{t('region.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {regions.map(region => (
                                    <tr key={region.id}>
                                        <td>{region.codeRegion}</td>
                                        <td>{region.nomRegion}</td>
                                        <td>{region.nomDistrict}</td>
                                        <td>{region.coordonneesGps}</td>
                                        <td>
                                            <Badge color={region.active === 'Y' ? 'success' : 'secondary'}>
                                                {region.active === 'Y' ? t('region.active') : t('region.inactive')}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button color="info" size="sm" className="me-2" onClick={() => handleEdit(region)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm" onClick={() => handleDelete(region.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSizeRegions + 1,
                                end: Math.min((currentPage + 1) * pageSizeRegions, totalElementsRegions),
                                total: totalElementsRegions,
                                currentPage: currentPage,
                                totalPages: totalPagesRegions
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPagesRegions - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPagesRegions - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPagesRegions - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        {selectedRegion ? t('region.edit') : t('region.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="codeRegion">{t('region.code')}</Label>
                                <Input
                                    id="codeRegion"
                                    name="codeRegion"
                                    value={formData.codeRegion}
                                    onChange={e => setFormData({ ...formData, codeRegion: e.target.value })}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomRegion">{t('region.name')}</Label>
                                <Input
                                    id="nomRegion"
                                    name="nomRegion"
                                    value={formData.nomRegion}
                                    onChange={e => setFormData({ ...formData, nomRegion: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('region.district')}</Label>
                                <div className="d-flex gap-2">
                                    <Input
                                        value={formData.nomDistrict}
                                        readOnly
                                        placeholder={t('region.selectDistrict')}
                                    />
                                    <Button color="secondary" onClick={toggleDistrictModal}>
                                        <FaMapMarkerAlt />
                                    </Button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="coordonneesGps">{t('region.coordinates')}</Label>
                                <Input
                                    id="coordonneesGps"
                                    name="coordonneesGps"
                                    value={formData.coordonneesGps}
                                    onChange={e => setFormData({ ...formData, coordonneesGps: e.target.value })}
                                    placeholder="Lat, Long"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="active">{t('region.status')}</Label>
                                <Input
                                    type="select"
                                    id="active"
                                    name="active"
                                    value={formData.active}
                                    onChange={e => setFormData({ ...formData, active: e.target.value })}
                                >
                                    <option value="Y">{t('region.active')}</option>
                                    <option value="N">{t('region.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('region.cancel')}</Button>
                            <Button color="success" type="submit">{t('region.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
                <Modal isOpen={districtModal} toggle={toggleDistrictModal} size="lg">
                    <ModalHeader toggle={toggleDistrictModal}>
                        {t('region.selectDistrict')}
                    </ModalHeader>
                    <ModalBody>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t('district.code')}</th>
                                    <th>{t('district.name')}</th>
                                    <th>{t('district.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {districts.map(district => (
                                    <tr key={district.id}>
                                        <td>{district.codeDistrict}</td>
                                        <td>{district.nomDistrict}</td>
                                        <td>
                                            <Button color="success" size="sm" onClick={() => handleDistrictSelect(district)}>
                                                {t('region.select')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            <Pagination>
                                <PaginationItem disabled={currentPageDistrict === 0}>
                                    <PaginationLink first onClick={() => handleDistrictPageChange(0)}>
                                        <FaAngleDoubleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPageDistrict === 0}>
                                    <PaginationLink previous onClick={() => handleDistrictPageChange(currentPageDistrict - 1)}>
                                        <FaAngleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                {[...Array(totalPagesDistricts)].map((_, index) => (
                                    <PaginationItem active={currentPageDistrict === index} key={index}>
                                        <PaginationLink onClick={() => handleDistrictPageChange(index)}>
                                            {index + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPageDistrict === totalPagesDistricts - 1}>
                                    <PaginationLink next onClick={() => handleDistrictPageChange(currentPageDistrict + 1)}>
                                        <FaAngleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPageDistrict === totalPagesDistricts - 1}>
                                    <PaginationLink last onClick={() => handleDistrictPageChange(totalPagesDistricts - 1)}>
                                        <FaAngleDoubleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </ModalBody>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Regions;