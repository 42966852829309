import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

// Import images
import errorImg from "../../assets/images/error-img.png";

// Utility function to parse query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ErrorPage = () => {
  const query = useQuery();
  const status = query.get("status") || "Error";
  const message = query.get("message") || "An unexpected error occurred";

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center my-5">
                <h1 className="fw-bold text-error">
                  {status === "0" ? "Network" : status.charAt(0)}{" "}
                  <span className="error-text">
                    0<img src={errorImg} alt="" className="error-img" />
                  </span>{" "}
                  {status === "0" ? "" : "0"}
                </h1>
                <h3 className="text-uppercase">{message}</h3>
                <div className="mt-5 text-center">
                  <Link to="/" className="btn btn-success waves-effect waves-light">
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ErrorPage;
