import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { cooperativeActions } from '../../../sagas/cooperativeSlice';
import * as XLSX from 'xlsx';

const Cooperatives = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { cooperatives, isFetching, totalElements, totalPages, pageNumber, pageSize } = useSelector(state => state.cooperatives);
    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedCooperative, setSelectedCooperative] = useState(null);

    useEffect(() => {
        dispatch(cooperativeActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage, dispatch]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData(initialFormData);
            setSelectedCooperative(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const initialFormData = {
        codeCooperative: '',
        nomCooperative: '',
        dateCreation: '',
        dateDebutPartenariat: '',
        nomPrenomsPresident: '',
        telephonePresident: '',
        nomPrenomsGerant: '',
        telephoneGerant: '',
        nomPrenomsComptable: '',
        telephoneComptable: '',
        nomPrenomsSecretaireGeneral: '',
        telephoneSecretaireGeneral: '',
        nomPrenomsTresorier: '',
        telephoneTresorier: '',
        nombreMembreHomme: '',
        nombreMembreFemme: '',
        siegeSocial: '',
        descriptions: '',
        nomPrenomsMandataire1: '',
        telephoneMandataire1: '',
        nomPrenomsMandataire2: '',
        telephoneMandataire2: '',
        nomBanquePaiement: '',
        ribBanquePaiement: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedCooperative?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedCooperative ? 'cooperative.update.successTitle' : 'cooperative.create.successTitle'),
                    text: t(selectedCooperative ? 'cooperative.update.successText' : 'cooperative.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(cooperativeActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedCooperative ? 'cooperative.update.errorTitle' : 'cooperative.create.errorTitle'),
                    text: error.message || t(selectedCooperative ? 'cooperative.update.errorText' : 'cooperative.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedCooperative ?
            cooperativeActions.update(payload) :
            cooperativeActions.create(payload)
        );
    };

    const handleEdit = (cooperative) => {
        setSelectedCooperative(cooperative);
        setFormData({
            codeCooperative: cooperative.codeCooperative,
            nomCooperative: cooperative.nomCooperative,
            dateCreation: cooperative.dateCreation,
            dateDebutPartenariat: cooperative.dateDebutPartenariat,
            nomPrenomsPresident: cooperative.nomPrenomsPresident,
            telephonePresident: cooperative.telephonePresident,
            nomPrenomsGerant: cooperative.nomPrenomsGerant,
            telephoneGerant: cooperative.telephoneGerant,
            nomPrenomsComptable: cooperative.nomPrenomsComptable,
            telephoneComptable: cooperative.telephoneComptable,
            nomPrenomsSecretaireGeneral: cooperative.nomPrenomsSecretaireGeneral,
            telephoneSecretaireGeneral: cooperative.telephoneSecretaireGeneral,
            nomPrenomsTresorier: cooperative.nomPrenomsTresorier,
            telephoneTresorier: cooperative.telephoneTresorier,
            nombreMembreHomme: cooperative.nombreMembreHomme,
            nombreMembreFemme: cooperative.nombreMembreFemme,
            siegeSocial: cooperative.siegeSocial,
            descriptions: cooperative.descriptions,
            nomPrenomsMandataire1: cooperative.nomPrenomsMandataire1,
            telephoneMandataire1: cooperative.telephoneMandataire1,
            nomPrenomsMandataire2: cooperative.nomPrenomsMandataire2,
            telephoneMandataire2: cooperative.telephoneMandataire2,
            nomBanquePaiement: cooperative.nomBanquePaiement,
            ribBanquePaiement: cooperative.ribBanquePaiement
        });
        toggle();
    };
    const handleDelete = (id) => {
        Swal.fire({
            title: t('cooperative.delete.title'),
            text: t('cooperative.delete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('cooperative.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cooperativeActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('cooperative.delete.successTitle'),
                            text: t('cooperative.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(cooperativeActions.search({ page: 0, size: pageSize }));
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('cooperative.delete.errorTitle'),
                            text: error.message || t('cooperative.delete.errorText'),
                            icon: 'error'
                        });
                    }
                }));
            }
        });
    };


    // Add export function
    const handleExport = async () => {

        Swal.fire({
            title: t('campagne.export.loading'),
            text: t('campagne.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let playload = {
            onSuccess: (data) => {
                // Format data for Excel
                const excelData = data.map(cooperative => ({
                    'Code': cooperative.codeCooperative,
                    'Nom': cooperative.nomCooperative,
                    'Date Création': cooperative.dateCreation ? new Date(cooperative.dateCreation).toLocaleDateString() : '',
                    'Date Début Partenariat': cooperative.dateDebutPartenariat ? new Date(cooperative.dateDebutPartenariat).toLocaleDateString() : '',
                    'Statut': cooperative.actif === 'Y' ? 'Actif' : 'Inactif',
                    'Président': cooperative.nomPrenomsPresident,
                    'Téléphone Président': cooperative.telephonePresident,
                    'Gérant': cooperative.nomPrenomsGerant,
                    'Téléphone Gérant': cooperative.telephoneGerant,
                    'Comptable': cooperative.nomPrenomsComptable,
                    'Téléphone Comptable': cooperative.telephoneComptable,
                    'Secrétaire Général': cooperative.nomPrenomsSecretaireGeneral,
                    'Téléphone Secrétaire': cooperative.telephoneSecretaireGeneral,
                    'Trésorier': cooperative.nomPrenomsTresorier,
                    'Téléphone Trésorier': cooperative.telephoneTresorier,
                    'Membres Hommes': cooperative.nombreMembreHomme || 0,
                    'Membres Femmes': cooperative.nombreMembreFemme || 0,
                    'Total Membres': (cooperative.nombreMembreHomme || 0) + (cooperative.nombreMembreFemme || 0),
                    'Siège Social': cooperative.siegeSocial,
                    'Description': cooperative.descriptions,
                    'Mandataire 1': cooperative.nomPrenomsMandataire1,
                    'Téléphone Mandataire 1': cooperative.telephoneMandataire1,
                    'Mandataire 2': cooperative.nomPrenomsMandataire2,
                    'Téléphone Mandataire 2': cooperative.telephoneMandataire2,
                    'Banque': cooperative.nomBanquePaiement,
                    'RIB': cooperative.ribBanquePaiement
                }));

                // Create worksheet
                const ws = XLSX.utils.json_to_sheet(excelData);

                // Create workbook
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Coopératives');

                // Generate Excel file
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create download link
                const fileName = `cooperative${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();

                Swal.fire({
                    title: t('campagne.export.successTitle'),
                    text: t('campagne.export.successText'),
                    icon: 'success',
                    timer: 1500
                });

            }, onError: (error) => {
                Swal.fire({
                    title: t('campagne.export.errorTitle'),
                    text: t('campagne.export.errorText'),
                    icon: 'error'
                });
            }

        }


        dispatch(cooperativeActions.findAll(playload));



    };

    return (
        <Card>
            <CardBody>

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('cooperative.title')}</h4>
                        <p className="text-muted small mb-0">{t('cooperative.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="success" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('cooperative.new')}
                        </Button>
                    </div>
                </div>

                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-success" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <Table hover bordered className="align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>{t('cooperative.code')}</th>
                                        <th>{t('cooperative.name')}</th>
                                        <th>{t('cooperative.president')}</th>
                                        <th>{t('cooperative.contact')}</th>
                                        <th>{t('cooperative.status')}</th>
                                        <th>{t('cooperative.actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cooperatives && cooperatives.map(cooperative => (
                                        <tr key={cooperative.id}>
                                            <td>{cooperative.codeCooperative}</td>
                                            <td>{cooperative.nomCooperative}</td>
                                            <td>{cooperative.nomPrenomsPresident}</td>
                                            <td>{cooperative.telephonePresident}</td>
                                            <td>
                                                <Badge color={cooperative.actif === 'Y' ? 'success' : 'secondary'}>
                                                    {cooperative.actif === 'Y' ? t('cooperative.active') : t('cooperative.inactive')}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Button color="info" size="sm" className="me-2" onClick={() => handleEdit(cooperative)}>
                                                    <FaEdit />
                                                </Button>
                                                <Button color="danger" size="sm" onClick={() => handleDelete(cooperative.id)}>
                                                    <FaTrash />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <small className="text-muted">
                                {t('actions.showing', {
                                    start: (currentPage) * pageSize + 1,
                                    end: Math.min((currentPage + 1) * pageSize, totalElements),
                                    total: totalElements,
                                    currentPage: currentPage,
                                    totalPages: totalPages
                                })}
                            </small>
                            <Pagination size="sm" className="mb-0">
                                <PaginationItem disabled={currentPage === 0}>
                                    <PaginationLink first onClick={() => handlePageChange(0)}>
                                        <FaAngleDoubleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPage === 0}>
                                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                        <FaAngleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPage === totalPages - 1}>
                                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                        <FaAngleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPage === totalPages - 1}>
                                    <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                        <FaAngleDoubleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </>
                )}

                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {selectedCooperative ? t('cooperative.edit') : t('cooperative.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            {/* Basic Info */}
                            <FormGroup>
                                <Label for="codeCooperative">{t('cooperative.form.basic.code')}</Label>
                                <Input id="codeCooperative" value={formData.codeCooperative} name="codeCooperative" disabled />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomCooperative">{t('cooperative.form.basic.name')}</Label>
                                <Input id="nomCooperative" value={formData.nomCooperative} name="nomCooperative" required />
                            </FormGroup>

                            {/* Dates */}
                            <FormGroup>
                                <Label for="dateCreation">{t('cooperative.form.dates.creation')}</Label>
                                <Input id="dateCreation" value={formData.dateCreation} name="dateCreation" type="date" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="dateDebutPartenariat">{t('cooperative.form.dates.partnershipStart')}</Label>
                                <Input id="dateDebutPartenariat" name="dateDebutPartenariat" type="date" />
                            </FormGroup>

                            {/* Leadership */}
                            <FormGroup>
                                <Label for="nomPrenomsPresident">{t('cooperative.form.leadership.president.name')}</Label>
                                <Input id="nomPrenomsPresident" name="nomPrenomsPresident" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="telephonePresident">{t('cooperative.form.leadership.president.phone')}</Label>
                                <Input id="telephonePresident" name="telephonePresident" />
                            </FormGroup>

                            {/* ...similar pattern for other leadership roles... */}

                            {/* Members */}
                            <FormGroup>
                                <Label for="nombreMembreHomme">{t('cooperative.form.members.male')}</Label>
                                <Input id="nombreMembreHomme" name="nombreMembreHomme" type="number" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nombreMembreFemme">{t('cooperative.form.members.female')}</Label>
                                <Input id="nombreMembreFemme" name="nombreMembreFemme" type="number" />
                            </FormGroup>

                            {/* Location */}
                            <FormGroup>
                                <Label for="siegeSocial">{t('cooperative.form.location.headquarters')}</Label>
                                <Input id="siegeSocial" name="siegeSocial" />
                            </FormGroup>

                            {/* Description */}
                            <FormGroup>
                                <Label for="descriptions">{t('cooperative.form.basic.description')}</Label>
                                <Input id="descriptions" name="descriptions" type="textarea" />
                            </FormGroup>

                            {/* Bank Details */}
                            <FormGroup>
                                <Label for="nomBanquePaiement">{t('cooperative.form.bank.name')}</Label>
                                <Input id="nomBanquePaiement" name="nomBanquePaiement" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="ribBanquePaiement">{t('cooperative.form.bank.rib')}</Label>
                                <Input id="ribBanquePaiement" name="ribBanquePaiement" />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('common.cancel')}</Button>
                            <Button color="success" type="submit">{t('common.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </CardBody>
        </Card>
    );
}
export default Cooperatives;
