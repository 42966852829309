import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import AppRoute from './routes/route';
import { authProtectedRoutes, publicRoutes } from './routes';
import Layout from './layout';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ErrorPage from './components/Error';

function App() {
  return (
    <React.Fragment>

        <Routes>
          {/* Public Routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={route.component}
              key={idx}
              exact={true}
            />
          ))}

          {/* Protected Routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AppRoute requiredRoles={route.roles}>
                  <Layout>{route.component}</Layout>
                </AppRoute>
              }
              key={idx}
              exact={true}
            />
          ))}

          {/* Error Page */}
          <Route path="/error" element={<ErrorPage />} />

          {/* Catch-all Route for Undefined Paths */}
          <Route path="*" element={<Navigate to="/error?status=404&message=Page%20Not%20Found" />} />
        </Routes>
        {/* <Modal
          isOpen={this.state.showLogoutConfirm} // Open the modal if true
          toggle={() => this.setState({ showLogoutConfirm: false })} // Close the modal
        >
          <ModalHeader toggle={() => this.setState({ showLogoutConfirm: false })}>
            Confirm Logout
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to log out?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => this.confirmLogout(true)}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => this.confirmLogout(false)}>
              No
            </Button>
          </ModalFooter>
        </Modal> */}
      </React.Fragment>
  );
}

export default App;
