import { createModule } from "saga-slice";
import parcelleService from "../services/parcelleService";
import { call, put } from "redux-saga/effects";

const parcelleSlice = createModule({
    name: "parcelle",
    initialState: {
        parcelles: [],
        isFetching: false,
        error: null,
        totalElements: 0,
        totalPages: 0,
        pageNumber: 0,
        pageSize: 5,
    },
    reducers: {
        search: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        findAll: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        fetchByProducteurCode: (state) => {
          state.isFetching = true;
            state.error = null;
        },
        create: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        update: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        delete: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        success: (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.parcelles = action.body;
        },
        failure: (state, action) => {
            state.isFetching = false;
            state.error = action.payload;
        }
    },
    sagas: (actions) => ({
        [actions.search]: function* ({ payload }) {
          try {
            const data = yield call(parcelleService.search, payload);
            yield put(actions.success(data));
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        },
        [actions.fetchByProducteurCode]: function* ({ payload }) {
          try {
            const {code, onSuccess } = payload;
            const data = yield call(parcelleService.fetchByProducteurCode, code);
            yield put(actions.success(data));
            if (onSuccess) onSuccess(data.body);
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        },
        [actions.findAll]: function* ({payload}) {
          try {
            const data = yield call(parcelleService.findAll, payload);
            yield put(actions.success(data));
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        },
        [actions.create]: function* ({ payload }) {
          try {
            const { formData, onSuccess } = payload;
            const data = yield call(parcelleService.create, formData);
            yield put(actions.success(data));
            if (onSuccess) onSuccess();
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        },
        [actions.update]: function* ({ payload }) {
          try {
            const { id, dto, onSuccess } = payload;
            const data = yield call(parcelleService.update, id, dto);
            yield put(actions.success(data));
            if (onSuccess) onSuccess();
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        },
        [actions.delete]: function* ({ payload }) {
          try {
            const { id, onSuccess } = payload;
            yield call(parcelleService.remove, id);
            if (onSuccess) onSuccess();
          } catch (error) {
            yield put(actions.failure(error.message));
          }
        }
      })
})

export const parcelleActions = parcelleSlice.actions;
export default parcelleSlice;
