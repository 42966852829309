// Front
// Authentication Module
import { rootReducer } from "saga-slice";
import masterDataModule from "./dataSlice";
import producteursSlice from "./producteursSlice";
import userModule from "./userSlice";
import cooperativeSlice from "./cooperativeSlice";
import campagneSlice from "./campagneSlice";
import districtSlice from "./districtSlice";
import cultureSlice from "./cultureSlice";
import typePieceSlice from "./typePieceSlice";
import varieteSlice from "./varieteSlice";
import regionSlice from "./regionSlice";
import prefectureSlice from "./prefectureSlice";
import zoneSlice from "./zoneSlice";
import sousPrefectureSlice from "./sousPrefectureSlice";
import sectionSlice from "./sectionSlice";
import faitiereSlice from "./faitiereSlice";
import ethnieSlice from "./ethnieSlice";
import authModule from "./authSlice";
import parcelleService from "../services/parcelleService";
import parcelleSlice from "./parcelleSlice";


const modules = [masterDataModule, producteursSlice, authModule, userModule, cooperativeSlice, campagneSlice,districtSlice, cultureSlice, typePieceSlice, varieteSlice, regionSlice, prefectureSlice, zoneSlice,sousPrefectureSlice, sectionSlice, faitiereSlice, ethnieSlice, parcelleSlice];

const appReducer = rootReducer(modules, {
  
});

export default appReducer;