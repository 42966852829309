import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { rootSaga } from "saga-slice";
import authModule from "./sagas/authSlice";
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import persistStore from "redux-persist/es/persistStore";
import masterDataModule from "./sagas/dataSlice";
import producteursSlice from "./sagas/producteursSlice";
import userModule from "./sagas/userSlice";
import cooperativeSlice from "./sagas/cooperativeSlice";
import campagneSlice from "./sagas/campagneSlice";
import districtSlice from "./sagas/districtSlice";
import cultureSlice from "./sagas/cultureSlice";
import typePieceSlice from "./sagas/typePieceSlice";
import varieteSlice from "./sagas/varieteSlice";
import regionSlice from "./sagas/regionSlice";
import prefectureSlice from "./sagas/prefectureSlice";
import zoneSlice from "./sagas/zoneSlice";
import sousPrefectureSlice from "./sagas/sousPrefectureSlice";
import sectionSlice from "./sagas/sectionSlice";
import faitiereSlice from "./sagas/faitiereSlice";
import ethnieSlice from "./sagas/ethnieSlice";
import appReducer from "./sagas/reducers";
import parcelleSlice from "./sagas/parcelleSlice";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth','masterData'] // Only persist auth state
};

const modules = [masterDataModule, producteursSlice, authModule, userModule, cooperativeSlice, campagneSlice,districtSlice, cultureSlice, typePieceSlice, varieteSlice, regionSlice, prefectureSlice, zoneSlice,sousPrefectureSlice, sectionSlice, faitiereSlice, ethnieSlice, parcelleSlice];
const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga(modules));

export { store, persistor };