import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AppRoute = ({ children, requiredRoles }) => {
	const { role } = useSelector(state => state.auth);
	const location = useLocation();

	const isAuthenticated = () => {
		const token = localStorage.getItem('accessToken');
		if (!token) return false;

		try {
			// Parse JWT payload
			const payload = JSON.parse(atob(token.split('.')[1]));
			const expiry = payload.exp * 1000; // Convert to milliseconds

			// Check if token is expired
			if (Date.now() >= expiry) {
				localStorage.removeItem('accessToken');
				localStorage.removeItem('refreshToken');
				return false;
			}
			return true;
		} catch (e) {
			return false;
		}
	};

	if (!isAuthenticated()) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (requiredRoles.length > 0 && !requiredRoles.includes(role)) {
		return <Navigate to="/unauthorized" state={{ from: location }} replace />;
	}

	return children;
};

export default AppRoute;
