import React from "react";
import { Navigate } from "react-router-dom";

import {
  LOGIN_PAGE,
  SIGNUP_PAGE,
  PRODUCTEURS_PAGE,
  DASHBOARD_PAGE,
  STOCK_PAGE,
  BARCODE_PAGE,
  SALES_PAGE,
  SALES_HISTORY_PAGE,
  REFUND_PAGE,
  REFUNDS_HISTORY_PAGE,
  PARCELLES_PAGE,
  BASEDATA_PAGE,
  USERS_PAGE,
  USERS_FORM,
} from "./routeConstants";
import LoginForm from "../components/Auth/Login";
import Dashboard from "../components/Dashboard";
import { ROLE_ADMIN, ROLE_AGENT } from "../helpers/constants";
import Landing from "../components/landing/Landing";
import ProducteurList from "../components/Producteurs/ProducteurList";
import ParcelleMain from "../components/Parcelles/ParcelleMain";
import DatabaseTabs from "../components/database";
import UserList from "../components/Utilisateurs";
import UserForm from "../components/Utilisateurs/UserForm";

const authProtectedRoutes = [
  {
    path: PRODUCTEURS_PAGE,
    component: <ProducteurList />,
    roles: [ROLE_ADMIN],
  },
  {
    path: PARCELLES_PAGE,
    component: <ParcelleMain />,
    roles: [ROLE_ADMIN],
  },
  {
    path: DASHBOARD_PAGE,
    component: <Dashboard />,
    roles: [ROLE_ADMIN],
  },
  {
    path: BASEDATA_PAGE,
    component: <DatabaseTabs />,
    roles: [ROLE_ADMIN],
  },
  {
    path: USERS_PAGE,
    component: <UserList />,
    roles: [ROLE_ADMIN],
  },
  {
    path: USERS_FORM,
    component: <UserForm />,
    roles: [ROLE_ADMIN],
  },
];

const publicRoutes = [
  { path: "/", exact: true, component: <Landing/> },
  // { path: SIGNUP_PAGE, component: <SignUpForm /> },
  { path: LOGIN_PAGE, component: <LoginForm /> },
];

export { authProtectedRoutes, publicRoutes };
