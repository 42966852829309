import React from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarText,
  Button,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import '../assets/css/App.css'; // Optional for custom styling
import logo from "../assets/images/logonav.png";
import { useTranslation } from 'react-i18next';
import { FaBarcode, FaBox, FaBoxes, FaDatabase, FaShoppingCart, FaTachometerAlt, FaTree, FaUndoAlt, FaUsers } from 'react-icons/fa';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { authActions } from '../sagas/authSlice';
import { BARCODE_PAGE, BASEDATA_PAGE, DASHBOARD_PAGE, PARCELLES_PAGE, PRODUCTEURS_PAGE, REFUND_PAGE, USERS_PAGE } from '../routes/routeConstants';

const Layout = ({ children }) => {
  const fullName = useSelector(state => state.auth.fullName);
  const role = useSelector((state) => state.auth.role);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: t('auth.logoutConfirmTitle'),
      text: t('auth.logoutConfirmText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(authActions.logout());
        Swal.fire({
          icon: 'success',
          title: t('auth.logoutSuccess'),
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          navigate('/login');
        });
      }
    });
  };


  return (
    <div className="app-wrapper d-flex flex-column min-vh-100">
      {/* Header */}
      <header>
        <Navbar className="navbar-light bg-white border-bottom px-4" expand="lg">
          <NavbarBrand href="/" className="d-flex align-items-center">
            <img
              src={logo}
              alt="AgriTracking"
              height={45}
              className="brand-logo"
            />
          </NavbarBrand>
          <NavbarText className="ms-auto">
            <UncontrolledDropdown>
              <DropdownToggle nav caret className="d-flex align-items-center">
                <FaUser className="me-2" />
                {t('user.greeting')}, {fullName}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={handleLogout}>
                  <FaSignOutAlt className="me-2" />
                  {t('user.signOut')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavbarText>
        </Navbar>
      </header>

      <div className="content-wrapper d-flex flex-grow-1">
        {/* Sidebar */}
        <aside className="sidebar bg-white border-end" >
          <Nav vertical className="sidebar-nav p-3">
            {role && role === 'ROLE_ADMIN' && (
              <>
                <NavItem>
                  <NavLink href={DASHBOARD_PAGE} className={({ isActive }) => `
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isActive ? 'bg-success text-white' : 'text-secondary hover-success'}
            `}
                  >
                    <FaTachometerAlt className="me-3" />
                    {t('nav.dashboard')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={PRODUCTEURS_PAGE} className={({ isActive }) => `
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isActive ? 'bg-success text-white' : 'text-secondary hover-success'}
            `}
                  >
                    <FaUsers className="me-3" />
                    {t('nav.producteurs')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={PARCELLES_PAGE} className={({ isActive }) => `
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isActive ? 'bg-success text-white' : 'text-secondary hover-success'}
            `}
                  >
                    <FaTree className="me-3" />
                    {t('nav.pacrelles')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={USERS_PAGE} className={({ isActive }) => `
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isActive ? 'bg-success text-white' : 'text-secondary hover-success'}
            `}
                  >
                    <FaUsers className="me-3" />
                    {t('nav.users')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={BASEDATA_PAGE} className={({ isActive }) => `
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isActive ? 'bg-success text-white' : 'text-secondary hover-success'}
            `}
                  >
                    <FaDatabase className="me-3" />
                    {t('nav.basedata')}
                  </NavLink>
                </NavItem>
                
              </>
            )}
          </Nav>
        </aside>

        {/* Main Content */}
        <main className="main-content flex-grow-1 bg-light">
          <Container fluid className="p-4">
            {children}
          </Container>
        </main>
      </div>

      {/* Footer */}
      <footer className="footer bg-white border-top py-3">
        <Container fluid className="px-4">
          <Row className="align-items-center">
            <Col md={6} className="text-start">
              <span className="text-muted">MatjarPos v1.0</span>
            </Col>
            <Col md={6} className="text-end">
              <span className="text-muted">© {new Date().getFullYear()} IDERACLOUD. All rights reserved.</span>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;

// App.css
