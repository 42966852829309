import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import '../../assets/css/Landing.css'; // Optional: for custom styles
import logo from '../../assets/images/logo.jpg'; // Optional: for custom logo
import { useSpring, animated } from '@react-spring/web';
import { FaArrowDown, FaBox, FaCheckCircle, FaCogs, FaEnvelope, FaGlobe, FaMap, FaShoppingCart, FaSignInAlt, FaStore, FaTruck, FaUserFriends, FaWarehouse } from 'react-icons/fa';

const LandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Set visibility after component mount
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const modules = [
    {
      id: 1,
      title: "Gestion des producteurs",
      description: "Identification des producteurs, Géolocalisation des producteurs, Suivi activités agricoles des producteurs",
      icon: <FaUserFriends className="module-icon" />,

    },
    {
      id: 2,
      title: "Gestion des parcelles",
      description: "Attribution d’un code unique par parcelle, enregistrement des superficies, localisation GPS et visualisation sur carte interactive.",
      icon: <FaMap className="module-icon" />,

    },
    {
      id: 3,
      title: "Conditionnement",
      description: "Gestion des étapes de tri, lavage, calibrage, séchage et emballage selon les normes définies, Attribution de numéros de lots, suivi des transformations et enregistrement des informations de conditionnement et de la qualité",
      icon: <FaBox className="module-icon" />,

    },
    {
      id: 4,
      title: "Achat",
      description: "Identification des producteurs, Préfinancement, produits achetés, quantités, prix et conditions de paiement",
      icon: <FaShoppingCart className="module-icon" />,
    },
    {
      id: 5,
      title: "Ventes",
      description: "Enregistrement des commandes clients, validation des quantités et suivi des paiements. Génération des factures, gestion des modes de paiement et suivi des encaissements. Vérification de la disponibilité des produits, préparation des expéditions et gestion des livraisons.",
      icon: <FaStore className="module-icon" />,
    },
    {
      id: 6,
      title: "Shipping",
      description: "Sélection des modes de transport, affectation des transporteurs et suivi des contrats logistiques. Génération des bordereaux d’expédition",
      icon: <FaTruck className="module-icon" />,

    },
    {
      id: 7,
      title: "Gestion des stocks",
      description: "Permet de suivre en temps réel les entrées, sorties et niveaux de stock afin d’optimiser la disponibilité des produits",
      icon: <FaWarehouse className="module-icon" />,

    },
    {
      id: 8,
      title: "Gestion de la maintenance",
      description: "Assure le suivi et l’entretien des infrastructures et équipements de stockage pour garantir des conditions optimales de conservation des produits",
      icon: <FaCogs className="module-icon" />,

    }
  ];

  // Animation for the header
  const headerAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
    delay: 200,
    config: { tension: 280, friction: 20 }
  });

  // Animation for the cards
  const cardAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'scale(1)' : 'scale(0.9)',
    delay: 400,
    config: { tension: 280, friction: 20 }
  });

  return (
    <div className="landing-page">
      <div className="login-wrapper">
        <Button
          color="success"
          className="login-button"
          href="/login"
        >
          <FaSignInAlt className="me-2" />
          Se Connecter
        </Button>
      </div>
      {/* Hero Section */}
      <div className="hero-section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="text-center text-lg-start">
              <Card className="hero-card">
                <CardBody>
                  <animated.div style={headerAnimation}>
                    <span className="pre-title">Plateforme de gestion agricole</span>
                    <h1 className="hero-title">AgriTracking</h1>
                    <div className="hero-features">
                      <div className="feature-item">
                        <FaCheckCircle className="check-icon" />
                        <span>Traçabilité complète</span>
                      </div>
                      <div className="feature-item">
                        <FaCheckCircle className="check-icon" />
                        <span>Gestion optimisée</span>
                      </div>
                      <div className="feature-item">
                        <FaCheckCircle className="check-icon" />
                        <span>Solution intégrée</span>
                      </div>
                    </div>
                    <p className="hero-subtitle">
                      AGRITRACKING couvre l'ensemble du cycle de gestion des produits agricoles,
                      de l'origine à l'achat auprès des producteurs jusqu'à la vente et la livraison aux clients.
                    </p>
                  </animated.div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="text-center mt-4 mt-lg-0">
              <animated.div style={cardAnimation}>
                <img src={logo} alt="AgriTracking Logo" className="hero-image" />
              </animated.div>
            </Col>
          </Row>
          <div className="text-center mt-1">
            <Button
              color="success"
              size="lg"
              className="discover-button"
              onClick={() => {
                document.querySelector('.features-section').scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            >
              Découvrir nos modules
              <FaArrowDown className="ms-2" />
            </Button>
          </div>
        </Container>
      </div>

      {/* Features Section */}
      <div className="features-section">
        <Container>
          <Card className="features-wrapper shadow">
            <CardBody className="py-5">
              <h2 className="section-title text-center mb-5">Nos Modules</h2>
              <Row>
                {modules.map(module => (
                  <Col md={6} lg={4} key={module.id} className="mb-4">
                    <Card className="feature-card h-100 border-0 shadow-sm">
                      <CardBody className="text-center">
                        {module.icon}
                        <CardTitle tag="h5" className="mt-3">{module.title}</CardTitle>
                        <CardText className="text-muted">{module.description}</CardText>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>

      {/* Footer Section */}
      <footer className="footer-section">
        <Container>
          <Row className="py-5">
            <Col md={6} className="mb-4 mb-md-0">
              <h4 className="mb-4">IDERACLOUD</h4>
              <p className="mb-3">
                Votre partenaire technologique pour des solutions innovantes et performantes
              </p>
            </Col>
            <Col md={6}>
              <h4 className="mb-4">Contactez-nous</h4>
              <ul className="list-unstyled contact-list">
                <li className="mb-2">
                  <a href="https://www.ideracloud.com" target="_blank" rel="noopener noreferrer">
                    <FaGlobe className="me-2" />
                    www.ideracloud.com
                  </a>
                </li>
                <li className="mb-2">
                  <a href="mailto:contact@ideracloud.com">
                    <FaEnvelope className="me-2" />
                    contact@ideracloud.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="footer-divider" />
          <div className="text-center py-3">
            <small className="text-muted">
              © {new Date().getFullYear()} IDERACLOUD. Tous droits réservés.
            </small>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage;