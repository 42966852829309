export const SIGNUP_PAGE = "/signup";
export const LOGIN_PAGE = "/login";
export const BASEDATA_PAGE = "/database";
export const PRODUCTEURS_PAGE = "/producteurs";
export const DASHBOARD_PAGE = "/dashboard";
export const USERS_PAGE = "/users";
export const USERS_FORM = "/users/form/:id?";
export const PARCELLES_PAGE = "/parcelles";
export const SALES_HISTORY_PAGE = "/sales/history";
export const REFUND_PAGE = '/refund';
export const REFUNDS_HISTORY_PAGE = "/refund/history";




