import { createModule } from 'saga-slice';
import userService from '../services/userService';
import { call, put } from 'redux-saga/effects';

const userModule = createModule({
  name: 'user',
  initialState: {
    users: [],
    isFetching: false,
    error: null,
    totalElements: 0, // Total number of records
    totalPages: 0, // Total number of pages
    pageNumber: 0, // Current page number
    pageSize: 10, // Size of each page
  },
  reducers: {
    list: (state) => {
      state.isFetching = true;
    },
    search: (state) => {
      state.isFetching = true;
    },
    fetchedData: (state, payload) => {
      state.users = payload;
      state.isFetching = false;
    },
    fetchError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload || 'An error occurred';
    },
    createUser: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    updateUser: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    delete: (state) => {
      state.isFetching = true;
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },
    createUserError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    updateUserError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchedData: (state, payload) => {
      state.users = payload.body.content; // Assuming content is in payload
      state.totalElements = payload.body.totalElements; // Assuming totalElements is in payload
      state.totalPages = payload.body.totalPages; // Assuming totalPages is in payload
      state.pageNumber = payload.body.pageNumber; // Upd
    },
  },
  sagas: (A) => ({
    *[A.list]({ payload }) {
      try {
        const users = yield call(userService.getUsers, payload);
        yield put(A.fetchedData(users));
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.search]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield call(userService.search, payload);
        yield put(A.finishFetching());
        yield put(A.fetchedData(data));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.delete]({ payload }) {
      try {
        yield yield call(userService.deleteRec,payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e?.response?.data?.message ||
          e.message ||
          "An error occurred during deletion.";
        yield call(payload.onError, { message: errorMessage });
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.createUser]({ payload }) {
      try {
        const { data } = yield call(userService.createUser, payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);

      } catch (error) {
        yield put(A.createUserError(error.message));
      }
    },
    *[A.updateUser]({ payload }) {
      try {
        const { data } = yield call(userService.updateUser, payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);
      } catch (error) {
        yield put(A.updateUserError(error.message));
      }
    },
  }),
});

export default userModule;
export const userActions = userModule.actions;