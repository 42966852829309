import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'; // For navigation
import '../../assets/css/LoginForm.css'; // Import custom CSS for styling
import bg from "../../assets/images/authentication-bg.jpg";
import logo from "../../assets/images/logoh.jpg";
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { authActions } from '../../sagas/authSlice';
import { useTranslation } from 'react-i18next';
import { FaUser, FaLock } from 'react-icons/fa';

const LoginForm = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation
  const dispatch = useDispatch();
  const { t } = useTranslation(); // Translation
  const formik = useFormik({
    initialValues: { username: "", password: "" },
    onSubmit: (values) => {
      dispatch(
        authActions.loginRequest({
          user: values,
          onSuccess: (data) => {
              navigate("/dashboard");
          },
        })
      );
    },
  });


  return (
    <div className="auth-wrapper">
      <div 
        className="auth-background"
        style={{
          backgroundImage: `url(${bg})`
        }}
      />
      <div className="auth-overlay" />

      <Container className="position-relative">
        <Row className="justify-content-end">
          <Col md={5}>
            <Card className="login-card">
              <CardBody className="p-4">
                {/* Logo Section */}
                <div className="text-center mb-4">
                  <img
                    src={logo}
                    alt="AgriTracking Logo"
                    className="login-logo"
                  />
                  <div className="logo-underline" />
                  <h3 className="welcome-text">
                    {t('auth.welcomeSignup')}
                  </h3>
                  <p className="login-subtitle">
                    {t('auth.loginPrompt')}
                  </p>
                </div>

                {/* Login Form */}
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="form-group">
                    <Label className="form-label">
                      {t('auth.username')}
                    </Label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FaUser />
                      </span>
                      <Input
                        type="text"
                        placeholder={t('auth.usernamePlaceholder')}
                        className="form-control-lg"
                        {...formik.getFieldProps('username')}
                        invalid={formik.touched.username && formik.errors.username}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="form-group">
                    <Label className="form-label">
                      {t('auth.password')}
                    </Label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FaLock />
                      </span>
                      <Input
                        type="password"
                        placeholder={t('auth.passwordPlaceholder')}
                        className="form-control-lg"
                        {...formik.getFieldProps('password')}
                        invalid={formik.touched.password && formik.errors.password}
                      />
                    </div>
                  </FormGroup>

                  <Button
                    type="submit"
                    className="login-button"
                    block
                  >
                    {t('auth.loginButton')}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginForm;
