import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { varieteActions } from '../../../sagas/varieteSlice';

const VarietesSemences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    varietes,
    isFetching,
    totalPages,
    totalElements,
    pageNumber,
    pageSize,
    error,
  } = useSelector((state) => state.varietes);
  const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
  const [modal, setModal] = useState(false);
  const [selectedVariete, setSelectedVariete] = useState(null);
  const [formData, setFormData] = useState({
    codeVariete: '',
    nomVariete: '',
    details: '',
    codeCulture: '',
    rendementHa: '',
    codeArticleStock: ''
  });

  useEffect(() => {
    dispatch(varieteActions.search({ page: currentPage, size: pageSize }));
  }, [currentPage]);

  const toggle = () => {
    setModal(!modal);
    if (modal) {
      setFormData({
        codeVariete: '',
        nomVariete: '',
        details: '',
        codeCulture: '',
        rendementHa: '',
        codeArticleStock: ''
      });
      setSelectedVariete(null);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      dto: formData,
      id: selectedVariete?.id,
      onSuccess: () => {
        toggle();
        Swal.fire({
          title: t(selectedVariete ? 'culture.update.successTitle' : 'culture.create.successTitle'),
          text: t(selectedVariete ? 'culture.update.successText' : 'culture.create.successText'),
          icon: 'success',
          timer: 1500
        });
        dispatch(varieteActions.search({ page: 0, size: 10 }));
      },
      onError: (error) => {
        Swal.fire({
          title: t(selectedVariete ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
          text: error.message || t(selectedVariete ? 'culture.update.errorText' : 'culture.create.errorText'),
          icon: 'error'
        });
      }
    };
    dispatch(selectedVariete ?
      varieteActions.update(payload) :
      varieteActions.create(payload)
    );
  };

  const handleExport = async () => {
    Swal.fire({
      title: t('variete.export.loading'),
      text: t('variete.export.wait'),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let payload = {
      onSuccess: (data) => {
        const excelData = data.map(variete => ({
          'Code': variete.codeVariete,
          'Nom': variete.nomVariete,
          'Culture': variete.codeCulture,
          'Rendement/Ha': variete.rendementHa || '',
          'Code Article Stock': variete.codeArticleStock || '',
          'Détails': variete.details || ''
        }));

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Variétés');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const fileName = `varietes-semences_${new Date().toISOString().split('T')[0]}.xlsx`;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();

        Swal.fire({
          title: t('variete.export.successTitle'),
          text: t('variete.export.successText'),
          icon: 'success',
          timer: 1500
        });
      },
      onError: (error) => {
        Swal.fire({
          title: t('variete.export.errorTitle'),
          text: t('variete.export.errorText'),
          icon: 'error'
        });
      }
    };

    dispatch(varieteActions.findAll(payload));
  };

  const handleEdit = (variete) => {
    setSelectedVariete(variete);
    setFormData({
      codeVariete: variete.codeVariete,
      nomVariete: variete.nomVariete,
      details: variete.details || '',
      codeCulture: variete.codeCulture,
      rendementHa: variete.rendementHa || '',
      codeArticleStock: variete.codeArticleStock || ''
    });
    toggle();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t('culture.delete.title'),
      text: t('culture.delete.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('culture.delete.confirm')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(varieteActions.delete({
          id,
          onSuccess: () => {
            Swal.fire({
              title: t('culture.delete.successTitle'),
              text: t('culture.delete.successText'),
              icon: 'success',
              timer: 1500
            });
            dispatch(varieteActions.search({ page: 0, size: pageSize }))
          },
          onError: (error) => {
            Swal.fire({
              title: t('culture.delete.errorTitle'),
              text: error.message || t('culture.delete.errorText'),
              icon: 'error'
            });
          }

        }));
      }
    });
  };


  return (
    <Card className="shadow-sm">
      <CardBody>

        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h4 className="card-title mb-1">{t('variete.title')}</h4>
            <p className="text-muted small mb-0">{t('variete.description')}</p>
          </div>
          <div className="d-flex gap-2">
            <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
              <FaFileExcel className="me-2" /> {t('common.download')}
            </Button>
            <Button color="success" className="d-flex align-items-center" onClick={toggle}>
              <FaPlus className="me-2" /> {t('variete.new')}
            </Button>
          </div>
        </div>

        {isFetching ? (
          <div className="text-center py-5">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">{t('common.loading')}</span>
            </div>
            <div className="mt-2 text-muted">
              {t('common.loadingData')}
            </div>
          </div>
        ) : (<>
          <div className="table-responsive">
            <Table hover responsive className="align-middle">
              <thead>
                <tr>
                  <th>{t('variete.code')}</th>
                  <th>{t('variete.name')}</th>
                  <th>{t('variete.details')}</th>
                  <th>{t('variete.culture')}</th>
                  <th>{t('variete.yield')}</th>
                  <th>{t('variete.stockCode')}</th>
                  <th>{t('variete.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {varietes.map(variete => (
                  <tr key={variete.id}>
                    <td>{variete.codeVariete}</td>
                    <td>{variete.nomVariete}</td>
                    <td>{variete.details}</td>
                    <td>{variete.codeCulture}</td>
                    <td>{variete.rendementHa}</td>
                    <td>{variete.codeArticleStock}</td>
                    <td>
                      <Button color="info" size="sm" className="me-2"
                        onClick={() => handleEdit(variete)}>
                        <FaEdit />
                      </Button>
                      <Button color="danger" size="sm"
                        onClick={() => handleDelete(variete.id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <small className="text-muted">
              {t('actions.showing', {
                start: (currentPage) * pageSize + 1,
                end: Math.min((currentPage + 1) * pageSize, totalElements),
                total: totalElements,
                currentPage: currentPage,
                totalPages: totalPages
              })}
            </small>
            <Pagination size="sm" className="mb-0">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink first onClick={() => handlePageChange(0)}>
                  <FaAngleDoubleLeft size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                  <FaAngleLeft size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages - 1}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                  <FaAngleRight size={12} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages - 1}>
                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                  <FaAngleDoubleRight size={12} />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        </>
        )}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {selectedVariete ? t('variete.edit') : t('variete.new')}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>

              <FormGroup>
                <Label for="nomVariete">{t('variete.name')}</Label>
                <Input
                  id="nomVariete"
                  name="nomVariete"
                  value={formData.nomVariete}
                  onChange={e => setFormData({ ...formData, nomVariete: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="details">{t('variete.details')}</Label>
                <Input
                  type="textarea"
                  id="details"
                  name="details"
                  value={formData.details}
                  onChange={e => setFormData({ ...formData, details: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="codeCulture">{t('variete.culture')}</Label>
                <Input
                  id="codeCulture"
                  name="codeCulture"
                  value={formData.codeCulture}
                  onChange={e => setFormData({ ...formData, codeCulture: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="rendementHa">{t('variete.yield')}</Label>
                <Input
                  type="number"
                  step="0.01"
                  id="rendementHa"
                  name="rendementHa"
                  value={formData.rendementHa}
                  onChange={e => setFormData({ ...formData, rendementHa: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="codeArticleStock">{t('variete.stockCode')}</Label>
                <Input
                  id="codeArticleStock"
                  name="codeArticleStock"
                  value={formData.codeArticleStock}
                  onChange={e => setFormData({ ...formData, codeArticleStock: e.target.value })}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>{t('common.cancel')}</Button>
              <Button color="success" type="submit">{t('common.save')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default VarietesSemences;