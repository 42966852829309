import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Polygon,
} from '@react-google-maps/api';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import { parcelleActions } from '../../sagas/parcelleSlice';
import { GOOGLE_MAP_API_KEY } from '../../helpers/constants';
import ProducteurSelectModal from './ProducteurSelectModal';
import { FaBarcode, FaCalendar, FaMapMarkedAlt, FaRedo, FaRuler, FaSearch, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ParcelleMain = () => {
  const dispatch = useDispatch();
  const { parcelles, isFetching } = useSelector((state) => state.parcelle);
  const masterData = useSelector((state) => state.masterData.data);
  const mapRef = useRef();
  const [isProducteurModalOpen, setIsProducteurModalOpen] = useState(false);
  const { t } = useTranslation();

  // Search filters
  const [filters, setFilters] = useState({
    campagneId: masterData?.actifCampagne?.id || null,
    codeProducteur: '',
    codeDistrict: '',
    codeZone: '',
    codeRegion: '',
    codePrefecture: '',
    codeSousPrefecture: '',
  });

  // Map settings
  const defaultCenter = { lat: 7.539989, lng: -5.547080 }; // Côte d'Ivoire center
  const defaultZoom = 7;

  const [map, setMap] = useState(null);
  const [selectedParcelle, setSelectedParcelle] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    dispatch(parcelleActions.findAll(filters)); // Initial load with filters
  }, [dispatch, filters]); // React to filter changes

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(parcelleActions.findAll(filters));
  };

  // Producteur Modal functions
  const toggleProducteurModal = () => {
    setIsProducteurModalOpen(!isProducteurModalOpen);
  };

  const selectProducteur = (codeProducteur) => {
    setFilters((prev) => ({
      ...prev,
      codeProducteur: codeProducteur,
    }));
    toggleProducteurModal();
  };

  // Modified function to return the centroid of the parcelle
  const parcelleToGoogleMapsCoordinates = (parcelle) => {
    if (!parcelle?.coordinates || parcelle.coordinates.length === 0) {
      return { path: [], avg: null };
    }

    const path = parcelle.coordinates.map((coord) => ({
      lat: coord.latitude,
      lng: coord.longitude,
    }));

    let totalLat = 0;
    let totalLng = 0;

    parcelle.coordinates.forEach((coord) => {
      totalLat += coord.latitude;
      totalLng += coord.longitude;
    });

    const avgLat = totalLat / parcelle.coordinates.length;
    const avgLng = totalLng / parcelle.coordinates.length;

    const avg = { lat: avgLat, lng: avgLng };

    return { path, avg };
  };

  const handleReset = () => {
    setFilters({
      campagneId: masterData?.actifCampagne?.id || null,
      codeProducteur: '',
      codeDistrict: '',
      codeZone: '',
      codeRegion: '',
      codePrefecture: '',
      codeSousPrefecture: '',
    });
    handleInfoWindowClose();
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const polygonOptions = {
    fillColor: '#38b000',
    fillOpacity: 0.7,
    strokeColor: '#fff',
    strokeWeight: 2,
    clickable: true,
    zIndex: 1,
  };

  const handleMarkerClick = (parcelle) => {
    setSelectedParcelle(parcelle);
    // if (map) {
    //   const { avg } = parcelleToGoogleMapsCoordinates(parcelle);
    //   map.panTo(avg); // Pan to the marker
    //   map.setZoom(15); // Zoom level
    // }
  };

  const handleInfoWindowClose = () => {
    setSelectedParcelle(null);
    if (map) {
      map.setZoom(defaultZoom); // Reset zoom level
    }
  };

  const mapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    gestureHandling: 'auto', // Enable smooth zoom on mobile
  };

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          {isFetching && (
            <div className="text-center py-5">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">{t('common.loading')}</span>
              </div>
              <div className="mt-2 text-muted">
                {t('common.loadingData')}
              </div>
            </div>)}
          <Card>
            <CardBody>
              <Form onSubmit={handleSearch}>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="producteurId">Producteur</Label>
                      <div className="d-flex align-items-center">
                        <Input
                          type="text"
                          id="codeProducteur"
                          value={filters?.codeProducteur}
                          onChange={handleFilterChange}
                        />
                        <Button
                          color="primary"
                          onClick={toggleProducteurModal}
                          className="ml-2"
                        >
                          <FaSearch />
                        </Button>

                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="codeRegion">Region</Label>
                      <Input
                        type="select"
                        name="codeRegion"
                        value={filters.codeRegion}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common.select')}</option>
                        {masterData.regions?.map((r) => (
                          <option key={r.code} value={r.code}>
                            {r.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="codeDistrict">District</Label>
                      <Input
                        type="select"
                        name="codeDistrict"
                        value={filters.codeDistrict}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common.select')}</option>
                        {masterData.districts?.map((d) => (
                          <option key={d.code} value={d.code}>
                            {d.description}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="codeZone">Zone</Label>
                      <div className="d-flex align-items-center">

                        <Input
                          type="select"
                          name="codeZone"
                          value={filters.codeZone}
                          onChange={handleFilterChange}
                          className='me-2'
                        >
                          <option value="">{t('common.select')}</option>
                          {masterData.zones?.map((z) => (
                            <option key={z.code} value={z.code}>
                              {z.description}
                            </option>
                          ))}
                        </Input>
                        <Button color="secondary" type="button" onClick={handleReset}>
                          <FaRedo />
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>

                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <div style={{ height: '700px' }}>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    zoom={defaultZoom}
                    center={defaultCenter}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={mapOptions}
                  >
                    {parcelles?.map((parcelle) => {
                      const { path } = parcelleToGoogleMapsCoordinates(parcelle);
                      return path.length > 0 ? (
                        <Polygon
                          key={parcelle.id}
                          paths={path}
                          options={{
                            fillColor: '#198754',
                            fillOpacity: 0.35,
                            strokeColor: '#198754',
                            strokeWeight: 2
                          }}
                          onClick={() => setSelectedParcelle(parcelle)}
                        />
                      ) : null;
                    })}

                    {parcelles?.map((parcelle) => {
                      const { avg } = parcelleToGoogleMapsCoordinates(parcelle);
                      return avg ? (
                        <Marker
                          key={parcelle.id}
                          position={avg}
                          onClick={() => handleMarkerClick(parcelle)}
                        >
                          {selectedParcelle?.id === parcelle?.id && (
                            <InfoWindow
                              position={avg}
                              onCloseClick={() => handleInfoWindowClose()}
                            >
                              <div style={{ padding: '8px' }}>
                                <h5 style={{ marginBottom: '8px' }}>Détails de la Parcelle</h5>
                                <p style={{ marginBottom: '4px' }}>
                                  <FaBarcode style={{ marginRight: '4px' }} />
                                  {parcelle.codeParcelle}
                                </p>
                                <p style={{ marginBottom: '4px' }}>
                                  <FaUser style={{ marginRight: '4px' }} />
                                  {parcelle.producteurCodeProducteur}
                                </p>
                                <p style={{ marginBottom: '4px' }}>
                                  <FaCalendar style={{ marginRight: '4px' }} />
                                  {parcelle.dateSemis}
                                </p>
                                <p style={{ marginBottom: '4px' }}>
                                  <FaRuler style={{ marginRight: '4px' }} />
                                  {parcelle.surfaceGps} ha
                                </p>
                                <p style={{ marginBottom: '4px' }}>
                                  <FaMapMarkedAlt style={{ marginRight: '4px' }} />
                                  {parcelle.surfaceReelle} ha
                                </p>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      ) : null;
                    })}
                  </GoogleMap>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Producteur Select Modal */}
      <ProducteurSelectModal
        isOpen={isProducteurModalOpen}
        toggle={toggleProducteurModal}
        onSelect={selectProducteur}
      />
    </Container>
  );
};

export default ParcelleMain;