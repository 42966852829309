import config from "../helpers/config";
import axiosInstance from "./api_helper";

export const loginUser = (query) => {
  let headers = {
      "Content-type": "application/json",
  };
  return axiosInstance.post(`/auth/login`, JSON.stringify(query), {
      headers: headers,
  });
};

export const signupUser = (query) => {
  let headers = {
      "Content-type": "application/json",
  };
  return axiosInstance.post(`/auth/signup`, JSON.stringify(query), {
      headers: headers,
  });
};
