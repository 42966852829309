import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMapMarked, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaBarcode, FaBox, FaBoxes, FaBoxOpen, FaCalendarTimes, FaCog, FaEdit, FaFileDownload, FaFileUpload, FaImage, FaMap, FaPlus, FaQrcode, FaSearch, FaTag, FaTrash, FaMapMarkerAlt, FaTable, FaThLarge, FaCalendarAlt, FaEye } from 'react-icons/fa';
import { Button, Card, CardBody, CardHeader, CardImg, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, Toast, ToastBody, ToastHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { producteursActions } from "../../sagas/producteursSlice";
import { debounce } from "lodash";

// ProducteurSelectModal Component
const ProducteurSelectModal = ({
    isOpen,
    toggle,
    onSelect,
}) => {
    const [isSearching, setIsSearching] = useState(false);
    const searchInputRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const masterData = useSelector((state) => state.masterData.data);
    const dispatch = useDispatch();
    const {
        producteurs,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.producteurs);
    const { t } = useTranslation();
    const [criteria, setSearchCriteria] = useState({ query: '' });

    useEffect(() => {
        let payload = {
            criteria,
            page: currentPage,
            size: pageSize,
        };
        dispatch(producteursActions.search(payload));
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce((query) => {
            setIsSearching(true);
            dispatch(producteursActions.search({
                criteria: { query },
                page: 1,
                size: pageSize,
                onSuccess: () => {
                    setIsSearching(false)
                    setTimeout(() => {
                        searchInputRef.current?.focus();
                    }, 0);
                }
            }));
        }, 1000), // Wait 500ms after last keystroke
        []
    );

    // Handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    const districtDescription = (producteur) =>
        masterData?.districts?.find(
            (district) => district.id === producteur.districtId
        )?.description || "N/A";

    const regionDescription = (producteur) =>
        masterData?.regions?.find((region) => region.id === producteur.regionId)
            ?.description || "N/A";

    const prefectureDescription = (producteur) =>
        masterData?.perfectures?.find(
            (prefecture) => prefecture.id === producteur.prefectureId
        )?.nomPrefecture || "N/A";

    const sousPrefectureDescription = (producteur) =>
        masterData?.sousPerfectures?.find(
            (sousPrefecture) => sousPrefecture.id === producteur.sousPrefectureId
        )?.nomSousPrefecture || "N/A";

    const zoneDescription = (producteur) =>
        masterData?.zones?.find((zone) => zone.id === producteur.zoneId)
            ?.description || "N/A";

    const cooperativeDescription = (producteur) =>
        masterData?.cooperatives?.find(
            (cooperative) => cooperative.id === producteur.cooperativeId
        )?.description || "N/A";

    const faitiereDescription = (producteur) =>
        masterData?.faitieres?.find(
            (faitiere) => faitiere.id === producteur.faitiereId
        )?.description || "N/A";


    return (
        <Modal isOpen={isOpen} toggle={toggle} size='xl'>
            <ModalHeader toggle={toggle}>Select Producteur</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12} className="pe-4">
                        <Card className="shadow-sm h-100">
                            <CardBody>
                                {isFetching ? (
                                    <div className="text-center py-5">
                                        <div className="spinner-border text-success" role="status">
                                            <span className="visually-hidden">{t('common.loading')}</span>
                                        </div>
                                        <div className="mt-2 text-muted">
                                            {t('common.loadingData')}
                                        </div>
                                    </div>
                                ) : (<>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        {/* Search Input - Left Side */}
                                        <div className="position-relative flex-grow-1 me-3">
                                            <Input
                                                innerRef={searchInputRef}
                                                type="text"
                                                placeholder={t('producteur.searchPlaceholder')}
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                className={`ps-4 ${isSearching ? 'searching' : ''}`}
                                            />
                                            <FaSearch
                                                style={{
                                                    position: 'absolute',
                                                    left: '10px',
                                                    top: '12px',
                                                    color: '#6c757d'
                                                }}
                                            />
                                        </div>
                                        

                                    </div>
                                    <div className="cart-container" style={{
                                        height: '400px',
                                        overflowY: 'auto'
                                    }}>
                                        <Table hover bordered className="align-middle mb-0">
                                            <thead className="bg-light">
                                                <tr>

                                                    <th className="align-middle text-end">
                                                        <FaTag className="me-2 text-success" size={12} />
                                                        {t('producteur.codeProducteur')}
                                                    </th>
                                                    <th className="align-middle text-end">
                                                        <FaTag className="me-2 text-success" size={12} />
                                                        {t('producteur.fullname')}
                                                    </th>
                                                    <th className="align-middle text-end">
                                                        <FaMap className="me-2 text-success" size={12} />
                                                        {t('producteur.emplacement')}
                                                    </th>
                                                    <th className="align-middle text-center" style={{ width: '120px' }}>
                                                        <FaCog className="me-2 text-success" size={12} />
                                                        {t('common.actions')}
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {!producteurs || producteurs.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="6" className="text-center py-4">
                                                            <div className="text-muted">
                                                                {t('producteur.noProducteurs')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    producteurs.map((producteur) => (
                                                        <tr key={producteur.id}>
                                                            <td className="align-middle text-center">{producteur.codeProducteur}</td>
                                                            <td className="align-middle text-end">{producteur.nomPrenoms}</td>
                                                            <td className="align-middle text-end">{
                                                                <React.Fragment>
                                                                    {districtDescription(producteur) + " / " + regionDescription(producteur) + " / " + zoneDescription(producteur) + " / " + prefectureDescription(producteur) + " / " + sousPrefectureDescription(producteur)}
                                                                </React.Fragment>
                                                            }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <Button color="primary" onClick={() => onSelect(producteur.codeProducteur)}>
                                                                    {t('common.select')}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </Table>

                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <small className="text-muted">
                                            {t('common.showing', {
                                                start: (currentPage - 1) * pageSize + 1,
                                                end: Math.min(currentPage * pageSize, totalElements),
                                                total: totalElements,
                                                currentPage: currentPage,
                                                totalPages: totalPages
                                            })}
                                        </small>
                                        <Pagination size="sm" className="mb-0">
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink first onClick={() => handlePageChange(1)}>
                                                    <FaAngleDoubleLeft size={12} />
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                                    <FaAngleLeft size={12} />
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem disabled={currentPage === totalPages}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                                    <FaAngleRight size={12} />
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem disabled={currentPage === totalPages}>
                                                <PaginationLink last onClick={() => handlePageChange(totalPages)}>
                                                    <FaAngleDoubleRight size={12} />
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ProducteurSelectModal;