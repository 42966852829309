import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, ButtonGroup, Button, Spinner, CardHeader } from 'reactstrap';
import { FaChartLine, FaBoxOpen, FaCalendarAlt, FaMoneyBillWave, FaFileExcel, FaBoxes, FaChartBar, FaBuilding, FaGlobe, FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { producteursActions } from '../../sagas/producteursSlice';

const Dashboard = () => {
  const [dateRange, setDateRange] = useState('today');
  const dispatch = useDispatch();
  const [data, setData] = useState({
    superficieCulture: [],
    byAgeGroup: [],
    byOrigine: [],
    byGenre: [],
    byZone: [],
    byDistrict: [],
    byRegion: [],
  });

  const [stats, setStats] = useState({
    agents: 0,
    superviseurs: 0,
    managers: 0,
    zones: 0,
    sections: 0,
    districts: 0,
    regions: 0,
    prefectures: 0,
    sousPrefectures: 0,
    faitieres: 0,
    cooperatives: 0,
    producteurs: 0,
    parcellesRecensees: { count: 0, superficie: 0 },
    parcellesMesurees: { count: 0, superficie: 0 }
  });

  const { t } = useTranslation();

  const masterData = useSelector((state) => state.masterData.data);
  const [campagneId, setCampagneId] = useState(masterData?.campagnes[0]?.id);
  useEffect(() => {
    const payload = {
      campagneId,
      onSuccess: (response) => {
        setData({
          superficieCulture: response.superficieCulture || [],
          byAgeGroup: response.byAgeGroup || [],
          byOrigine: response.byOrigine || [],
          byGenre: response.byGenre || [],
          byZone: response.byZone || [],
          byDistrict: response.byDistrict || [],
          byRegion: response.byRegion || [],
        });
  
        // Update stats with the received data
        setStats({
          agents: response.totalAgents,
          superviseurs: response.totalSuperviseurs,
          managers: response.totalManagers,
          zones: response.totalZones,
          sections: response.totalSections,
          districts: response.totalDistricts,
          regions: response.totalRegions,
          prefectures: response.totalPrefectures,
          sousPrefectures: response.totalSousPrefectures,
          faitieres: response.totalFaitieres,
          cooperatives: response.totalCooperatives,
          producteurs: response.totalProducteurs,
          parcellesRecensees: { 
            count: response.totalParcellesRecensees, 
            superficie: response.totalSuperficieRecensee 
          },
          parcellesMesurees: { 
            count: response.totalParcellesMesurees, 
            superficie: response.totalSuperficieMesuree 
          }
        });
      },
      onError: (error) => {
        console.log(error);
      },
    };
  
    dispatch(producteursActions.dashboard(payload));
  }, [campagneId, dispatch]);

  const getChartData = (data, nameKey, countKey) => {
    return data?.map((item) => ({
      name: item[nameKey],
      count: item[countKey],
    }));
  };

  const GENRE_COLORS = {
    Homme: "#FF7F3E",
    Femme: "#001A6E",
  };
  const ORIGINE_COLORS = {
    Autochtone: "#FF7F3E",
    Allochtone: "#001A6E",
    Allogène: "#5CB338",
  };
  const AGE_COLORS = {
    "Moins de 35 ans": "#3D0301",
    "De 35 a 54 ans": "#E73879",
    "Plus de 55 ans": "#31511E",
  };

  const colorPalette = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F333FF",
    "#FF33A5",
    "#FFC300",
    "#DAF7A6",
    "#581845",
    "#900C3F",
    "#C70039",
    "#FF5733",
    "#FF8D1A",
    "#33C1FF",
  ];
  const assignColors = (dataArray) => {
    const colorMap = {};
    let colorIndex = 0;

    dataArray.forEach((item) => {
      colorMap[item.description] =
        colorPalette[colorIndex % colorPalette.length]; // Loop through the palette
      colorIndex++;
    });

    return colorMap;
  };
  const REGION_COLORS = assignColors(masterData.regions);
  const ZONE_COLORS = assignColors(masterData.zones);
  const DISTRICT_COLORS = assignColors(masterData.districts);


  return (
    <Container fluid>
      {/* Date Range Selector */}
      <Row className="mb-4">
        <Col>
          <Card className="shadow-sm">
            <CardBody className="d-flex justify-content-between align-items-center">
              <h4 className="mb-0 d-flex align-items-center">
                <FaCalendarAlt className="text-success me-2" />
                {t('dashboard.pageTitle')}
              </h4>
              <select
                name="campagneId"
                value={campagneId}
                onChange={(e) => setCampagneId(e.target.value)}
                required
                className="ml-3 custom-select"
              >
                {masterData.campagnes.map((campagne) => (
                  <option key={campagne.id} value={campagne.id}>
                    Campagne : {campagne.description}
                  </option>
                ))}
              </select>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="3" className="mb-4">
          <Card className="shadow-sm border-0 h-100">
            <CardBody className="p-4">
              <div className="d-flex justify-content-between align-items-start">
                <div className="flex-grow-1">
                  <h6 className="fw-bold text-muted mb-3">
                    <FaUsers className="text-success me-2" />
                    {t('dashboard.users')}
                  </h6>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.agents')}</span>
                      <span className="fw-bold">{stats.agents}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.superviseurs')}</span>
                      <span className="fw-bold">{stats.superviseurs}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.managers')}</span>
                      <span className="fw-bold">{stats.managers}</span>
                    </div>
                  </div>
                  <div className="mt-3 pt-3 border-top">
                    <span className="text-success fw-bold">
                      {stats.agents + stats.superviseurs + stats.managers}
                    </span>
                    <small className="text-muted ms-2">{t('dashboard.total')}</small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="3" className="mb-4">
          <Card className="shadow-sm border-0 h-100">
            <CardBody className="p-4">
              <div className="d-flex justify-content-between align-items-start">
                <div className="flex-grow-1">
                  <h6 className="fw-bold text-muted mb-3">
                    <FaGlobe className="text-success me-2" />
                    {t('dashboard.geography')}
                  </h6>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.zones')}</span>
                      <span className="fw-bold">{stats.zones}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.sections')}</span>
                      <span className="fw-bold">{stats.sections}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.districts')}</span>
                      <span className="fw-bold">{stats.districts}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.regions')}</span>
                      <span className="fw-bold">{stats.regions}</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="3" className="mb-4">
          <Card className="shadow-sm border-0 h-100">
            <CardBody className="p-4">
              <div className="d-flex justify-content-between align-items-start">
                <div className="flex-grow-1">
                  <h6 className="fw-bold text-muted mb-3">
                    <FaBuilding className="text-success me-2" />
                    {t('dashboard.administrative')}
                  </h6>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.prefectures')}</span>
                      <span className="fw-bold">{stats.prefectures}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.sousPrefectures')}</span>
                      <span className="fw-bold">{stats.sousPrefectures}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.faitieres')}</span>
                      <span className="fw-bold">{stats.faitieres}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.cooperatives')}</span>
                      <span className="fw-bold">{stats.cooperatives}</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="3" className="mb-4">
          <Card className="shadow-sm border-0 h-100">
            <CardBody className="p-4">
              <div className="d-flex justify-content-between align-items-start">
                <div className="flex-grow-1">
                  <h6 className="fw-bold text-muted mb-3">
                    <FaChartBar className="text-success me-2" />
                    {t('dashboard.production')}
                  </h6>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="text-muted">{t('dashboard.producteurs')}</span>
                      <span className="fw-bold">{stats.producteurs}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <span className="text-muted">{t('dashboard.parcellesRecensees')}</span>
                      <div className="text-end">
                        <div className="fw-bold">{stats.parcellesRecensees.count}</div>
                        <small className="text-success">{stats.parcellesRecensees.superficie} Ha</small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <span className="text-muted">{t('dashboard.parcellesMesurees')}</span>
                      <div className="text-end">
                        <div className="fw-bold">{stats.parcellesMesurees.count}</div>
                        <small className="text-success">{stats.parcellesMesurees.superficie} Ha</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Sales Summary Cards */}
      <Row className="mb-4">
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Genre</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    // data={getChartData(data?.byGenre, "key", "count")}
                    data={data.byGenre}
                    dataKey="count"
                    nameKey="key"
                    cx="40%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byGenre.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byGenre.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={GENRE_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="left"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Origine</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data.byOrigine}
                    dataKey="count"
                    nameKey="key"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byOrigine.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byOrigine.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={ORIGINE_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="lett"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Classe d'age</h5>
              </div>
            </CardHeader>
            <CardBody>

              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data.byAgeGroup}
                    dataKey="count"
                    nameKey="key"
                    cx="30%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byAgeGroup.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byAgeGroup.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={AGE_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="left"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>

      </Row>
      <Row>
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Region</h5>
              </div>
            </CardHeader>
            <CardBody>

              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data.byRegion}
                    dataKey="count"
                    nameKey="key"
                    cx="40%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byRegion.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byRegion.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={REGION_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="left"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">District</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data.byDistrict}
                    dataKey="count"
                    nameKey="key"
                    cx="30%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byDistrict.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byDistrict.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={DISTRICT_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="left"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Zone</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data.byZone}
                    dataKey="count"
                    nameKey="key"
                    cx="40%"
                    cy="50%"
                    outerRadius={100}
                    label={({ count }) => {
                      const total = data.byZone.reduce(
                        (sum, entry) => sum + entry.count,
                        0
                      );
                      const percentage = ((count / total) * 100).toFixed(1);
                      return `${count} (${percentage}%)`;
                    }}
                  >
                    {data?.byZone.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={ZONE_COLORS[entry.key]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    layout="vertical"
                    verticalAlign="right"
                    align="left"
                  />{" "}
                </PieChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="shadow-sm h-100">
            <CardHeader>
              <div className="d-flex align-items-center">
                <FaBoxes className="text-success me-2" />
                <h5 className="mb-0">Superficies Cultures Agricoles</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={getChartData(
                    data?.superficieCulture,
                    "key",
                    "superficie"
                  )}
                  margin={{ top: 50 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8" barSize={30}>
                    {" "}
                    <LabelList
                      dataKey="count"
                      position="top"
                      content={({ x, y, value }) => {
                        return (
                          <text
                            x={x}
                            y={y}
                            dy={-6}
                            fill="#000"
                            textAnchor="middle"
                          >
                            {`${value} ha`}
                          </text>
                        );
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container >
  );
};

export default Dashboard;